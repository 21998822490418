/* eslint-disable */
import {v4 as uuidv4} from 'uuid';
export const addLoadingMessage = (stateCallback, type, message) => {
    stateCallback((messageArray) => [
            ...messageArray,
            {
                type,
                message
            }
        ]);
};

export const formater = () => {
    const formatNumber = (value) => {
        if (value !== null) {
            return parseFloat(value).toFixed(2);
        }
        return value;
    };

    const formatDate = (date) => {
        function addLeadingZero(n) {
            return n < 10 ? `0${n}` : n;
        }
        const dateDate = new Date(date);
        let dateString = '';
        dateString += `${addLeadingZero(dateDate.getFullYear())}-${addLeadingZero(
            dateDate.getMonth() + 1
        )}-${addLeadingZero(dateDate.getDate())}`;
        dateString += ` ${addLeadingZero(dateDate.getHours())}:${addLeadingZero(dateDate.getMinutes())}:${addLeadingZero(
            dateDate.getSeconds()
        )}`;
        return dateString;
    };

    return { formatNumber, formatDate };
};

export const checkAndSetDeviceId = async () => {
    let deviceId = await localStorage.getItem('device_id');
    if(!deviceId) await localStorage.setItem('device_id', uuidv4());
};

export const getBrowserName = () => {

    var isOpera = (!!window.opr && !!window.opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
    if(isOpera) return "Opera";

    // Firefox 1.0+
    var isFirefox = typeof InstallTrigger !== 'undefined';
    if(isFirefox) return "Firefox";

    // Safari 3.0+ "[object HTMLElementConstructor]"
    var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
    if(isSafari) return "Safari";

    // Internet Explorer 6-11
    var isIE = /*@cc_on!@*/false || !!document.documentMode;
    if(isIE) return "InternetExplorer";

    // Edge 20+
    var isEdge = !isIE && !!window.StyleMedia;
    if(isEdge) return "Edge";
    // Chrome 1 - 79
    var isChrome = !!window.chrome;

    var isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") != -1);
    if(isEdgeChromium) return "Edge";
    else if(isChrome) return "Chrome";

    var standalone = window.navigator.standalone,
        userAgent = window.navigator.userAgent.toLowerCase(),
        safari = /safari/.test( userAgent ),
        ios = /iphone|ipod|ipad/.test( userAgent );

    if( ios ) {
        if ( !standalone && safari ) {
            return "iOS";
        } else if ( standalone && !safari ) {
            return "iOS";
        } else if ( !standalone && !safari ) {
            return "iOS";
        };
    }

    const isWebView = navigator.userAgent.includes ('wv');
    if(isWebView) return "Android";

    return "Unknown";
};