import React from 'react';
import { Route, Switch, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { StyledButtonBack, Container, StyledReactSVG, Title, Text} from '../Pages.style';
import {ButtonWrapper} from './ChangeShift.style'
import backIcon from '../../assets/images/icons/chevron-left-solid.svg';
import RequestForChangeShift from './requestForChangeShift/RequestForChangeShift';
import ChangeSfihtHeader from './ChangeShiftHeader';

const ChangeShift = () => {

  const { t } = useTranslation();

  return (
    <Container>
    <Title>
      <StyledButtonBack>
        <NavLink to='/'>
          <StyledReactSVG src={backIcon}></StyledReactSVG>
        </NavLink>
      </StyledButtonBack>
      <Text>
        {t('main.changeWorkShifts')}</Text>
    </Title>
    <ButtonWrapper>
    <ChangeSfihtHeader></ChangeSfihtHeader>
    </ButtonWrapper>
  <Switch>
    <Route path='/changeShift/requestForChangeShift' component={RequestForChangeShift}></Route>

  </Switch>
  </Container>
  )
}

export default ChangeShift
