import React, { useEffect, useState } from 'react'
import { SelectComponentsWrapper, FilterSelectInput, ChangeShiftButton } from './RequestForChangeShift.style';
import { StyledButtonBack, Container, StyledReactSVG, Title, Text } from '../../Pages.style';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import backIcon from '../../../assets/images/icons/chevron-left-solid.svg';
import useShiftsChange from '../hooks/useChangeShifts';
import { useAuthStateValue } from '../../../context/AuthContext';
import ATextArea from '../../../core/components/inputs/textArea/ATextArea';
import { useHistory } from "react-router-dom";

const RequestForChangeShift = () => {

    const { t } = useTranslation();
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const { loggedUser } = useAuthStateValue();
    const [myShiftDay, setMyShiftDay] = useState();
    const [myShiftId, setMyShiftId] = useState();
    const [elseShift, setElseShift] = useState();
    const [elseShiftId, setElseShiftId] = useState();
    const [changeShiftDay, setChangeShiftDay] = useState(null);
    const [comment, setComment] = useState();

    const history = useHistory();

    const locationIdentificator = loggedUser?.extendUser?.locationIdentificator;
    const locationId = loggedUser?.locationsData?.find((el) => el?.value === locationIdentificator)?.id;
    const userApplicantId = parseInt(loggedUser?.id, 10);


    const {
        dataSource,
        version,
        sendRequest,
        error,
        requestList
      } = useShiftsChange({ atcu: locationIdentificator, year, month, userId: loggedUser?.id });


    function addLeadingZero(n) {
        return n < 10 ? `0${n}` : n;
    }

    const remainingDays = [];
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    const today = currentDate.getDate();
    const remainingDaysInMonthElseShift = () => {
        const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
        for (let day = today + 1; day <= lastDayOfMonth; day++) {
        remainingDays.push({
            value: day,
            label: (addLeadingZero(day) + '.' + addLeadingZero(currentMonth) + '.' + currentYear + '.')
        });

    }
    return remainingDays;
    }

    const myShifts = [];
    const remainingDaysOfMonthMyShift = () => {
        const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
        dataSource?.controllers?.find(obj => obj?.id == loggedUser?.id)?.shifts?.forEach((item) => {
            if (item.date >= today) {
                myShifts.push({
                    value: item.date,
                    shiftId1: item.shiftId,
                    label: `${addLeadingZero(item.date) + '.' + addLeadingZero(month) + '.' + year + '.' + '(' + item.shiftName + ')'}`,
                    date: item?.date
                })
            }
            }) 
    }

    remainingDaysOfMonthMyShift();
    remainingDaysInMonthElseShift();
    
    const peopleForRequest = [];
    dataSource?.controllers?.forEach((element) => {
        element.shifts?.forEach((item) => {
            if(item.date === changeShiftDay){
                peopleForRequest?.push({
                    name: element.firstName + ' ' + element.lastName,
                    label: element.firstName + ' ' + element.lastName + ' ('+ item.shiftName + ')',
                    shiftId2: item.shiftId,
                    value: element.id,
                    day: item.date,
                    userRecipientRequestId: element.id
                })
            }
        })
    })

    let buttonDisabled = true;
    let myShiftChosen = false;

    const myShiftFunction = (value) => {
        const helper =(myShifts?.find((el) => el.date == value));
        setMyShiftDay(value);
        setMyShiftId(helper?.shiftId1);
    }

    if (myShiftId !== null) {
        myShiftChosen = true;
    }

    let changeShiftDayChosen = false;
    const changeShiftDayFunction = (value) => {
        setChangeShiftDay(value);
    }

    if (changeShiftDay !== null) {
        changeShiftDayChosen = true;
    }

    const changeShiftPersonFunction = (value) => {
        const helper = (peopleForRequest?.find((el) => el.value === value))
        setElseShift(value);
        setElseShiftId(helper?.shiftId2);
    }
  
    const commentHandler = (e) => {
        setComment(e.target.value);
    }

    if (elseShift !== undefined && comment !== undefined) {
        buttonDisabled = false;
    }

    const sendRequestHandler = () => {
        const params = {
          userApplicantId: userApplicantId,
          userRecipientRequestId: elseShift,
          monthlyScheduleId: version?.id,
          day1: myShiftDay,
          shiftConfigId1: myShiftId,
          day2: changeShiftDay,
          shiftConfigId2: elseShiftId,
          comment: comment
        };
        sendRequest(params);
      };

    return (
        <Container>
        <Title>
            <StyledButtonBack><NavLink to='/changeShift'><StyledReactSVG src={backIcon}></StyledReactSVG></NavLink></StyledButtonBack>
            <Text>{t('changeShift.requestForChangeShift')}</Text>
        </Title>
        <SelectComponentsWrapper>
            <Text style={{ fontSize: '18px', marginTop: '8px' }}>{t('requestForChangeShift.myShift')}</Text>
            <FilterSelectInput 
                options={myShifts}
                listHeight={150}
                onChange={myShiftFunction}
            ></FilterSelectInput>
            <Text style={{ fontSize: '18px', marginTop: '8px' }}>{t('requestForChangeShift.changeShiftDay')}</Text>
            <FilterSelectInput 
                 options={remainingDays}
                 disabled={!myShiftChosen}
                 onChange={changeShiftDayFunction}
                 listHeight={150}
            ></FilterSelectInput>
            {changeShiftDayChosen && <Text style={{ fontSize: '18px', marginTop: '8px' }}>{t('requestForChangeShift.changeShiftPerson')}</Text> }
            {changeShiftDayChosen && <FilterSelectInput 
                options={peopleForRequest}
                onChange={changeShiftPersonFunction}
                listHeight={150}
            ></FilterSelectInput> }
             {changeShiftDayChosen && <Text style={{ fontSize: '18px', marginTop: '8px' }}>{t('requestForChangeShift.explanation')}</Text> }
            {changeShiftDayChosen &&
            <ATextArea rows={4} style={{ width: '100%' }} onChange={commentHandler} />
        }
        </SelectComponentsWrapper>
                <ChangeShiftButton style={{ width: '200px', height: '50px' }} onClick={sendRequestHandler} disabled={buttonDisabled}>{t('requestForChangeShift.sendRequest')}</ChangeShiftButton>
        </Container>
        )
}
    
export default RequestForChangeShift