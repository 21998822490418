import styled from 'styled-components';
import ATextInput from '../../../core/components/inputs/textInput/ATextInput';
import '../../../index.css';

export const ValidateField = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  width: 100%;
`;

export const ErrorText = styled.div`
  display: flex;
  height: 11px;
  width: 100%;
  align-items: flex-start;
  color: #ffb4b4;
  font-size: 11px;
`;

export const ErrorSpace = styled.div`
  height: 11px;
  width: 100%;
`;

export const LoginInputField = styled(ATextInput)`
  border-radius: 0px;
  padding-right: 35px;
  height: 38px;
  display: flex;
  border-bottom: 1px solid white;
  border-top: none;
  border-left: none;
  border-right: none;
  color: white;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0);
  @media only screen and (max-width: 320px) {
    width: 280px;
    align-self: center;
  }
  @media only screen and (max-width: 300px) {
    width: 270px;
    align-self: center;
  }
  input {
    font-size: 16px !important;
    width: 100%;
    border: none;
    background-color: rgba(255, 255, 255, 0);    
    color: white;
    text-align: center;
    
    :: placeholder {
     color: white;
    }
  }
  input:focus-visible {
    outline: none;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }
`;

export const LoginInputPasswordField = styled(ATextInput.Password)`
  border-radius: 0px;
  height: 38px;
  display: flex;
  border-bottom: 1px solid white;
  color: white;
  border-top: none;
  border-left: none;
  border-right: none;
  background-color: rgba(255, 255, 255, 0);
  overflow: hidden;
  @media only screen and (max-width: 320px) {
    width: 280px;
    align-self: center;
  }
  @media only screen and (max-width: 300px) {
    width: 270px;
    align-self: center;
  }
  input {
    font-size: 16px !important;
    width: 100%;
    border: none;
    background-color: rgba(255, 255, 255, 0);    
    color: white;
    text-align: center;
    
    :: placeholder {
     color: white;
    }
  }
  input:focus-visible {
    outline: none;
  }
  .ant-input-suffix svg
  {
    // padding-top: 10px;
    font-size: 20px;
    color: white;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  //border-right: 1px solid white;
  align-items: center;
    & .ant-input-affix-wrapper-focused {
    border-bottom: 1px solid white !important;
    box-shadow: none;
  }
`;

export const LoginButton = styled.button`
  width: 300px;
  height: 38px;
  background-color: #0073ae;
  font-size: 18px;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  outline: none;
  border: none;
  transition: 0.3s all;
  &:hover {
    background-color: #4d9dc6;
  }
  @media only screen and (max-width: 320px) {
    width: 280px;
    align-self: center;
  }
  @media only screen and (max-width: 300px) {
    width: 270px;
    align-self: center;
  }
`;
