import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import JwtDecode from 'jwt-decode';
import { useHistory } from 'react-router-dom';
import { login as loginApi, logout as logoutAPI, refresh as refreshApi } from '../services/auth.service';
import { useApplicationStateValue } from './ApplicationContext';
import { getAllGlobalPermissions, getAllLocationPermissions } from '../services/permissions.service';
import { getLocations } from '../services/locations.service';

const defaultState = {
    isLoggedIn: null,
    accessToken: null,
    refreshToken: null,
    loggedUser: null
};

const defaultActions = {
    login: () => {},
    logout: () => {},
    setLoggedIn: () => {}
};

export const AuthContext = React.createContext({
    ...defaultState,
    ...defaultActions
});

export const AuthConsumer = AuthContext.Consumer;

const AuthProvider = (props) => {
    const [isLoggedIn, setLoggedIn] = useState(false);
    const [accessToken, setAccessToken] = useState(null);
    const [refreshToken, setRefreshToken] = useState(null);
    const [loggedUser, setLoggedUser] = useState({
      firstName: null,
      lastName: null,
      username: null,
      id: null,
      globalPermissions: null,
      locationPermissions: null,
      extendUser: null,
      locationsData: null
    });
    const { setLoading } = useApplicationStateValue();
    const history = useHistory();

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');
    const globalPermissions = JSON.parse(sessionStorage.getItem('globalPermissions'));
    const localPermissions = JSON.parse(sessionStorage.getItem('locationPermissions'));
    const extendUser = JSON.parse(sessionStorage.getItem('extendUser'));
    const locationsData = JSON.parse(sessionStorage.getItem('locationsData'));

    if ((accessToken && refreshToken) && (!globalPermissions || !localPermissions || !extendUser || !locationsData)) {
      refresh({ accessToken, refreshToken });
    }
  }, []);

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    const globalPermissions = JSON.parse(sessionStorage.getItem('globalPermissions'));
    const localPermissions = JSON.parse(sessionStorage.getItem('locationPermissions'));
    const extendUser = JSON.parse(sessionStorage.getItem('extendUser'));
    const locationsData = JSON.parse(sessionStorage.getItem('locationsData'));

    const permittedRoutes = [
      {
        key: 'dashboard'
      }
    ];
    if (localPermissions) {
      // eslint-disable-next-line no-restricted-syntax,no-unused-vars
      for (const [key, value] of Object.entries(localPermissions)) {
        if (value.annualPlan && value.annualPlan !== 'NONE' && permittedRoutes.findIndex((el) => el.key === 'annualPlan') === -1) {
          permittedRoutes.push({ key: 'annualPlan' });
        }
        if (value.monthlyPlan && value.monthlyPlan !== 'NONE' && permittedRoutes.findIndex((el) => el.key === 'monthlyPlan') === -1) {
          permittedRoutes.push({ key: 'monthlyPlan' });
        }
        if (value.dailyPlan && value.dailyPlan !== 'NONE' && permittedRoutes.findIndex((el) => el.key === 'dailyPlan') === -1) {
          permittedRoutes.push({ key: 'dailyPlan' });
        }
        if (value.changeWorkShift && value.changeWorkShift !== 'NONE' && permittedRoutes.findIndex((el) => el.key === 'workShiftChange') === -1) {
          permittedRoutes.push({ key: 'changeWorkShift' });
        }
      }
    }
    if (globalPermissions?.settingUserAccountsDataAndStaffPermissions && globalPermissions?.settingUserAccountsDataAndStaffPermissions !== 'NONE') {
      permittedRoutes.push({ key: 'users' });
    }
    if (globalPermissions?.permissionSettingSystem && globalPermissions?.permissionSettingSystem !== 'NONE') {
      permittedRoutes.push({ key: 'settings' });
    }
    if (globalPermissions?.permissionReport && globalPermissions?.permissionReport !== 'NONE') {
      permittedRoutes.push({ key: 'reports' });
    }
    if (accessToken) {
      try {
        const tokenObj = JwtDecode(accessToken);
        setLoggedUser({
          firstName: tokenObj.first_name,
          lastName: tokenObj.last_name,
          username: tokenObj.sub,
          id: tokenObj.jti,
          globalPermissions,
          localPermissions,
          permittedRoutes,
          extendUser,
          locationsData
        });
        setLoggedIn(true);
      } catch (e) {
        setLoggedIn(false);
        setLoggedUser(false);
        console.error(e);
      }
      // TODO Send request to backend to check jwt or?
    }
    setLoading(false);
  }, [isLoggedIn, accessToken]);

  const fetchAllGlobalPermissions = async (userId) => {
    const res = await getAllGlobalPermissions({ userId });
    return {
      settingUserAccountsDataAndStaffPermissions: res?.data[0]?.settingUserAccountsDataAndStaffPermissions,
      permissionReport: res?.data[0]?.permissionReport,
      permissionSettingSystem: res?.data[0]?.permissionSettingSystem
    }
  }

  const fetchLocations = async (userId) => {
     const locationsDataArray = [];
     const res = await getLocations(userId);
     res?.data?.forEach((element) => {
      locationsDataArray.push({
        key: element.id,
        label: element.name,
        value: element.identificator,
        ...element
      });
     });
    return locationsDataArray;
  }
  const fetchAllLocationPermissions = async (userId) => {
    const locationPermissionsObj = {};
    const res = await getAllLocationPermissions({ userId });
    res?.data?.forEach((locationPermission) => {
      locationPermissionsObj[locationPermission.location.id] = {
        annualPlan: locationPermission.annualPlan,
        monthlyPlan: locationPermission.monthlyPlan,
        dailyPlan: locationPermission.dailyPlan,
        changeWorkShift: locationPermission.changeWorkShift
      }
    })
    return locationPermissionsObj;
  }

  const refresh = async ({ accessToken, refreshToken }) => {
    try {
      setLoading(true);
      const res = await refreshApi({ accessToken, refreshToken });
      if (res?.data?.accessToken) {
        localStorage.setItem('accessToken', res?.data?.accessToken);
        localStorage.setItem('refreshToken', res?.data?.refreshToken);
        await sessionStorage.setItem('extendUser', JSON.stringify(res?.data?.user?.extendUser))
        const tokenObj = JwtDecode(res?.data?.accessToken);
        // Fetch global and local permissions of logged user and store it in session storage
        const globalPermissions = await fetchAllGlobalPermissions(tokenObj.jti);
        await sessionStorage.setItem('globalPermissions', JSON.stringify(globalPermissions));
        // Map fetched location permissions for each location and store it to sessions storage
        const locationPermissions = await fetchAllLocationPermissions(tokenObj.jti);
        await sessionStorage.setItem('locationPermissions', JSON.stringify(locationPermissions));
        // Fetch locations of logged user and store it in session storage
        const locationsData = await fetchLocations(tokenObj.jti);
        await sessionStorage.setItem('locationsData', JSON.stringify(locationsData));
        // Set loggedIn state to true
        setAccessToken(res?.data?.accessToken);
        setRefreshToken(res?.data?.refreshToken);
        setLoggedUser({
          ...res?.data?.user,
          usergroupKey: res?.data?.user?.userGroup?.key
        });
        setLoggedIn(true);
        setTimeout(() => {
          setLoading(false);
        }, 50);
      }
    } catch (e) {
      console.log(e);
      setAccessToken(null);
      setRefreshToken(null);
      setLoggedUser(null);
      setLoggedIn(false);
      setLoading(false);
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      throw e;
    } finally {
      setLoading(false);
    }
  };

    const login = async ({ username, password }) => {
      try {
        const response = await loginApi({
          username,
          password
        });
        const { accessToken, refreshToken, user } = response.data;
        if (accessToken) {
          setLoading(true);
          setAccessToken(accessToken);
          setRefreshToken(refreshToken);
          setLoggedUser({
            ...user,
            usergroupKey: user.userGroup?.key
          });
          localStorage.setItem('accessToken', accessToken);
          localStorage.setItem('refreshToken', refreshToken);
          sessionStorage.setItem('extendUser', JSON.stringify(user.extendUser));
          const tokenObj = JwtDecode(accessToken);
          // Fetch global and local permissions of logged user and store it in session storage
          const globalPermissions = await fetchAllGlobalPermissions(tokenObj.jti);
          sessionStorage.setItem('globalPermissions', JSON.stringify(globalPermissions));
          // Map fetched location permissions for each location and store it to sessions storage
          const locationPermissions = await fetchAllLocationPermissions(tokenObj.jti);
          sessionStorage.setItem('locationPermissions', JSON.stringify(locationPermissions));
          // Fetch locations of logged user and store it in session storage
          const locationsData = await fetchLocations(tokenObj.jti);
          sessionStorage.setItem('locationsData', JSON.stringify(locationsData));
          // Set loggedIn state to true
          setLoggedIn(true);
          setTimeout(() => {
            setLoading(false);
          }, 50);
        }
      } catch (error) {
        console.log(error);
        setAccessToken(null);
        setRefreshToken(null);
        setLoggedUser(null);
        setLoggedIn(false);
        setLoading(false);
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        throw error;
      }
    };

    const logout = async () => {
      await (async function () {
        try {
          await logoutAPI();
          localStorage.removeItem('accessToken');
          setLoggedIn(false);
          setLoggedUser(null);
          history.push('/');
        } catch (err) {
          console.error(err);
        }
      }());
    };
    const state = {
      isLoggedIn,
      accessToken,
      refreshToken,
      loggedUser,
      setLoggedIn,
      login,
      logout,
      setLoggedUser
    };

    return <AuthContext.Provider value={state}>{props.children}</AuthContext.Provider>;
  }

  AuthProvider.propTypes = {
    children: PropTypes.node.isRequired
  };

export const useAuthStateValue = () => React.useContext(AuthContext);

export default AuthProvider;
