const theme = {
  colors: {
    mainColors: {
      blue: '#185da9',
      white: '#ffffff',
      red: '#ed1b23',
      black: '#000000',
      orange: 'orange',
      green: '#0F8F3E',
      notificationsGreen: 'green'
    },
    secondaryColors: {
      lightRed: '#fe9397',
      lightBlue: '#549bea',
      darkRed: '#c30b12',
      darkBlue: '#124885'
    },
    grayColors: {
      panelBackgroundGray: '#f3f6f6',
      gray: '#7f8f98',
      grayDark: '#c4c4c4',
      lightGray: '#e6e6e6',
      borderLineGray: '#b6c7cf',
      black: '#312f2f',
      mediumGray: '#a7a7a7',
      darkerGray: 'rgb(100 109 115)'
    }
  },
  fontSizes: [10, 12, 13, 16, 18, 21, 24, 32, 40, 44, 48],
  fontWeights: {
    light: 200,
    semiLight: 300,
    normal: 400,
    semiBold: 600,
    bold: 700
  },
  sizes: [12, 14, 16, 18, 20, 24, 30, 32]
};

export default theme;
