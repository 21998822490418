import React from 'react';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import { withTheme } from 'styled-components';
import {
    StyledMenuItem,
    MenuItemBox,
    MenuItemIconBox,
    Icon,
    StyledSubMenuItemValue,
    StyledMenu,
    StyledSubMenu,
    StyledSider,
    StyledMenuItemValue,
    SubMenuTitle,
    MenuItemBoxSpan
} from './SideMenu.style';
import config from '../../util/config';
import { AuthContext } from '../../context/AuthContext';

let dom = {};
try {
    // eslint-disable-next-line no-undef
    dom = require('react-router-dom');
} catch (e) {
    console.error(e);
}
const { Link, withRouter } = dom;
class SideMenu extends React.Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);
        this.state = {
            collapsed: localStorage.getItem('collapsedMenu') === 'true' ? 1 : 0,
            item: null,
            selectedMenuKey: this.props.menuItems[0]?.key,
            openKeys: []
        };
    }

    componentDidMount() {
        const path = this.props.location.pathname;
        const permittedRoutes = this?.context?.loggedUser?.permittedRoutes;
        const findSelectedMenuItem = (pathUrlKey) => {
            if (pathUrlKey) {
                let isFound = false;
                let menuItem = null;
                permittedRoutes.find((mi) => {
                    if (!isFound) {
                        if (mi.subMenuItems) {
                            const subItem = mi.subMenuItems.find((subItem) => subItem.key === pathUrlKey);
                            if (subItem) {
                                isFound = true;
                                menuItem = subItem;
                                this.setState({ openKeys: [mi.key] });
                            }
                        } else if (mi.key === pathUrlKey) {
                            isFound = true;
                            menuItem = mi;
                        }
                    }
                    return false;
                });
                return menuItem ? menuItem.key : pathUrlKey;
            }
            const openKeys = [];
            let menuItem = null;
            permittedRoutes.find((ruleMenuItem) => {
                if (ruleMenuItem.subMenuItems) {
                    if (!menuItem) {
                        ruleMenuItem.subMenuItems.find((ruleMenuSubItem) => {
                            const submenuItem = this.props.menuItems.find((item) => item.key === ruleMenuSubItem.key);
                            if (submenuItem && !menuItem) {
                                menuItem = {
                                    ...submenuItem,
                                    ...ruleMenuSubItem
                                };
                                openKeys.push(ruleMenuItem.key);
                            }
                        });
                        this.setState({ openKeys });
                    }
                } else {
                    permittedRoutes.find((authRule) => {
                        if (!menuItem) {
                            const menuItemFound = this.props.menuItems.find((mi) => mi.key === authRule.key);
                            if (menuItemFound) {
                                menuItem = {
                                    ...menuItemFound,
                                    ...authRule
                                };
                            }
                        }
                    });
                }
            });
            return menuItem.key;
        };
        const item = path !== '/' ? path.substr(1) : findSelectedMenuItem() || null;
        this.setState({ item, selectedMenuKey: item });

        this.unlisten = this.props.history.listen((location) => {
            const path = location.pathname;
            const item = path !== '/' ? path.substr(1) : this.props.menuItems[0].key || null;
            this.setState({ item });
        });
    }

    componentWillUnmount() {
        this.unlisten();
    }

    onCollapse = (collapsed) => {
        localStorage.setItem('collapsedMenu', collapsed);
        this.setState({ collapsed });
    };

    renderMenuItem = (item, isSubitem) => {
        const { key, icon, value } = item;
        const selected = this.state.selectedMenuKey === key;
        return (
          <StyledMenuItem
            title={(
              <div>
                {item.value}
              </div>
                  )}
            subitem={isSubitem || ''}
            collapsed={this.state.collapsed ? 1 : 0}
            key={`${key} ${this.state.collapsed}`}
            selected={selected}
            onClick={() => {
                    this.setState({ selectedMenuKey: key });
                }}
          >
            <Link to={`/${key}`}>
              {isSubitem ? (
                <MenuItemBox>
                  <MenuItemIconBox>
                    <Icon selected={selected}>
                      <ReactSVG src={icon} />
                    </Icon>
                  </MenuItemIconBox>
                  <StyledSubMenuItemValue selected={selected}>{value}</StyledSubMenuItemValue>
                </MenuItemBox>
                    ) : (
                      <MenuItemBox>
                        <MenuItemIconBox>
                          <Icon selected={selected}>
                            <ReactSVG src={icon} />
                          </Icon>
                        </MenuItemIconBox>
                        <StyledMenuItemValue selected={selected}>{value}</StyledMenuItemValue>
                      </MenuItemBox>
                    )}
            </Link>
          </StyledMenuItem>
        );
    };

    renderMenuItems = () => {
        const items = this.props.menuItems || [];
        const permittedRoutes = this?.context?.loggedUser?.permittedRoutes;
        if (permittedRoutes) {
            const filteredItems = permittedRoutes.reduce((filtered, rule) => {
                const item = items.find((i) => i.key === rule.key);
                if (rule.subMenuItems?.length > 0 && item) {
                    const filteredSubmenuItems2 = rule.subMenuItems.reduce((filteredSubmenuItems, ruleSubmenuItem) => {
                        const submenuItem = items.find((i) => i.key === ruleSubmenuItem.key);
                        if (submenuItem) {
                            filteredSubmenuItems.push({
                                ...submenuItem
                            });
                        }
                        return filteredSubmenuItems;
                    }, []);
                    if (filteredSubmenuItems2.length > 0) {
                        filtered.push({
                            ...rule,
                            value: this.props.t(rule.value, rule.value),
                            icon: item.icon,
                            subItems: filteredSubmenuItems2,
                            subMenuItems: filteredSubmenuItems2
                        });
                    }
                    return filtered;
                }
                if (item) {
                    let filteredSubItems = null;
                    if (rule.subMenuItems) {
                        filteredSubItems = rule.subMenuItems.reduce((filteredSubItems, ruleSubMenu) => {
                            const subItem = items.find((i) => i.key === ruleSubMenu.key);
                            if (ruleSubMenu && subItem) {
                                filteredSubItems.push({
                                    ...subItem,
                                    value: ruleSubMenu.value
                                        ? i18next.exists(ruleSubMenu.value)
                                            ? this.props.t(ruleSubMenu.value)
                                            : subItem.value
                                        : subItem.value
                                });
                            }
                            return filteredSubItems;
                        }, []);
                    }
                    if ((!filteredSubItems || filteredSubItems.length === 0) && !items.find((i) => i.key === item.key)) {
                        return filtered;
                    }
                    filtered.push({
                        ...item,
                        value: rule.value ? (i18next.exists(rule.value) ? this.props.t(rule.value) : item.value) : item.value,
                        subItems: filteredSubItems
                    });
                }
                return filtered;
            }, []);
            return filteredItems.map((item) => {
                if (!item.subItems || item.subItems.length === 0) {
                    return this.renderMenuItem(item, false);
                }
                let isOpen = 0;
                permittedRoutes.find((ruleMenuItem) => {
                    if (ruleMenuItem.subMenuItems) {
                        const ruleSubMenuItemFound = ruleMenuItem.subMenuItems.find((subMenuItem) => subMenuItem.key === this.state.selectedMenuKey);
                        if (ruleSubMenuItemFound && ruleMenuItem.key === item.key) {
                            isOpen = 1;
                        }
                    }
                });
                return (
                  <StyledSubMenu
                    key={item.key}
                    isOpen={isOpen}
                    title={(
                      <MenuItemBoxSpan isOpen={isOpen}>
                        <MenuItemIconBox>
                          <Icon>
                            <ReactSVG src={item.icon} />
                          </Icon>
                        </MenuItemIconBox>
                        <SubMenuTitle isOpen={isOpen}>{item.value}</SubMenuTitle>
                      </MenuItemBoxSpan>
                          )}
                  >
                    {item.subItems.map((subItem) => this.renderMenuItem(subItem, true))}
                  </StyledSubMenu>
                );
            });
        }
        return null;
    };

    render() {
        return (
          <StyledSider
            collapsedWidth={65}
            collapsible
            width={config.SIDEBAR_WIDTH || 200}
            collapsed={this.state.collapsed}
            onCollapse={this.onCollapse}
          >
            <StyledMenu
              selectedKeys={[this.state.item]}
              mode="inline"
              openKeys={this.state.openKeys}
              onOpenChange={(openKeys) => {
                        this.setState({ openKeys });
                    }}
            >
              {this.renderMenuItems()}
            </StyledMenu>
          </StyledSider>
        );
    }
}

let whatToExport;
if (dom.Route) {
    whatToExport = withTheme(withRouter(withTranslation()(SideMenu)));
} else {
    whatToExport = () => <div>No React Router</div>;
}

SideMenu.propTypes = {
    menuItems: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired
        })
    ),
    // location: PropTypes.object,
    history: PropTypes.object,
    t: PropTypes.func
};

export default whatToExport;
