
/* eslint-disable arrow-body-style */

import api from './base.service';

export const getAllGlobalPermissions = (payload) => {
    const params = {
      ...payload
    }
    return api().get('/api/user-global-permissions', { params });
};

export const updateGlobalPermissions = (userId, payload) => {
    const params = {
      ...payload
    }
    return api().put('/api/user-global-permissions/' + userId, { ...params });
}

export const getAllLocationPermissions = (payload) => {
    const params = {
        ...payload
    }
    return api().get('/api/user-location-permissions', { params });
};

export const updateLocationPermissions = (userId, payload) => {
    return api().put('/api/user-location-permissions/' + userId, payload);
};
