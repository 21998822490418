import styled from 'styled-components';
import { Layout } from 'antd';

export const Username = styled.div`
  display: flex;
  font-size: 15px;
  justify-content: right;
  align-items: center;
  height: 20px;
  user-select: none;
  color: white;
  font-family: Montserrat, regular, san-serif !important;
`;

export const UserRole = styled.div`
  display: flex;
  font-size: 10px;
  align-items: center;
  height: 12px;
  color: white;
  justify-content: flex-end;
  user-select: none;
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  color: white;
  cursor: auto;
`;

export const StyledLayoutHeader = styled(Layout.Header)`
  display: flex;
  //background-color: #0073ae;
  background-color: ${({ theme: { colors } }) => colors.mainColors.blue};
  color: white;
  justify-content: center;
  gap: 5px;
  display: flex;
  line-height: ${({ height }) => height + 'px'};
  padding: 0 0px;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 5;
  height: ${({ height }) => height + 'px'};
`;

export const Title = styled.div`
  color: white;
  font-size: 28px;
  @media only screen and (max-width: 330px) {
    font-size: 26px;
  }
  @media only screen and (max-width: 320px) {
    font-size: 24px;
  }
  @media only screen and (max-width: 290px) {
    font-size: 22px;
  }
`;

export const RightSideBox = styled.div`
  display: flex;

`;

export const LogoBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const SideMenuLogo = styled.img`
  display: block;
  margin: 0 auto;
  //border-radius: 4px;
  width: 50px;
  height: 50px;
`;
