import React, { useState, useEffect } from 'react'
import { ShiftContainer,
    NoRequestsLabel, 
    RequestListItem,
    RequestListItemCaret, 
    RequestStatusLabel,
    ListItemActionBox,
    ListItemActionButton,
    ListItemCommentContainer,
    ListItemControllerName,
    ListItemDate,
    ListItemShiftContainer,
    ListItemShiftName,
    ListItemShiftsContainer,
    ListSwapIcon,
    RequestListItemsContainer,
    RequestResponseBox,
    RequestTimestampsBox,
    UserResponseLabel } from './ChangeShiftRequests.style';
import { StyledButtonBack, Container, StyledReactSVG, Title, Text } from '../../Pages.style';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import backIcon from '../../../assets/images/icons/chevron-left-solid.svg';
import { useAuthStateValue } from '../../../context/AuthContext';
import { QuestionOutlined} from '@ant-design/icons';
import confirmModal from '../../../core/components/modals/ConfirmModal';
import { Input } from 'antd';
import useShiftsChange from '../hooks/useChangeShifts';
import dropDownIcon from '../../../assets/images/icons/caret-right.svg';
import swapIcon from '../../../assets/images/icons/awesome-exchange-alt.svg';
import approveIcon from '../../../assets/images/icons/Icon awesome-check-circle.svg';
import rejectIcon from '../../../assets/images/icons/Icon material-cancel.svg';
import { ReactSVG } from 'react-svg';
import { Tooltip } from 'antd';


const ChangeShiftRequests = () => {

    const { t } = useTranslation();
    const { loggedUser } = useAuthStateValue();
    const locationIdentificator = loggedUser?.extendUser?.locationIdentificator;
    const locationId = loggedUser?.locationsData?.find((el) => el?.value === locationIdentificator)?.id;
    
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const [expandedRequestKeys, setExpandedRequestKeys] = useState([]);

    const {getCurrentMonthScheduleVersions, requestList, respondToRequest } = useShiftsChange({ atcu: locationIdentificator, year, month, userId: loggedUser?.id });


      useEffect(() => {
        getCurrentMonthScheduleVersions();
      }, []);

    const approveRequestHandler = (request) => {
        confirmModal({
          centered: true,
          icon: <ReactSVG src={approveIcon} style={{ color: '#0F8F3E', float: 'left', marginRight: '10px' }} />,
          title: t('shiftsChange.approveRequest'),
          content: t('shiftsChange.approveRequestConfirm'),
          okText: t('buttons.yes'),
          cancelText: t('buttons.no'),
          onOk: () => {
            respondToRequest(request?.id, loggedUser?.id, 'APPROVED');
          }
        });
      };
    
      const rejectRequestHandler = (request) => {
        confirmModal({
          centered: true,
          icon: <ReactSVG src={rejectIcon} style={{ color: '#C44536', float: 'left', marginRight: '10px' }} />,
          title: t('shiftsChange.rejectRequest'),
          content: t('shiftsChange.rejectRequestConfirm'),
          okText: t('buttons.yes'),
          cancelText: t('buttons.no'),
          onOk: () => {
            respondToRequest(request?.id, loggedUser?.id, 'REJECTED');
          }
        });
      }

    return (
        <Container style={{gap: '0px'}}>
         <Title>
             <StyledButtonBack><NavLink to='/changeShift'><StyledReactSVG src={backIcon}></StyledReactSVG></NavLink></StyledButtonBack>
             <Text style={{fontSize: '20px'}}>{t('changeShift.changeShiftRequests')}</Text>
        </Title>
        <ShiftContainer>
          <RequestListItemsContainer>
            {
              requestList?.length === 0 && <NoRequestsLabel>{t('shiftsChange.noRequests')}</NoRequestsLabel>
            }
            {requestList?.length > 0 && requestList?.map((request) => {
              const expanded = expandedRequestKeys?.find((el) => el === request?.id);
              return (
                <RequestListItem key={request?.id} expanded={expanded}>
                  <RequestStatusLabel
                      onClick={() => {
                        if (expanded) {
                          setExpandedRequestKeys((prevState) => {
                            return prevState.filter((el) => el !== request?.id);
                          });
                        } else {
                          setExpandedRequestKeys((prevState) => {
                            return [
                              ...prevState,
                              request?.id
                            ];
                          });
                        }
                      }}>
                    <RequestListItemCaret
                      expanded={expanded}
                  
                      src={dropDownIcon}
                      beforeInjection={(svg) => {
                        svg.classList.add('svg-class-name')
                        svg.setAttribute('style', 'height: 20px;margin-left: 10px;margin-top: 5px;')
                      }}
                    />
                    {'Broj zahtjeva: ' + request?.id}
                  </RequestStatusLabel>
                  <ListItemShiftsContainer>
                    <ListItemShiftContainer>
                      <ListItemControllerName>{request?.userApplicant?.firstName + ' ' + request?.userApplicant?.lastName}</ListItemControllerName>
                      <ListItemShiftName>{request?.shiftConfigName1}</ListItemShiftName>
                      <ListItemDate>{new Date(year, month - 1, request?.day1).toLocaleDateString('sr-rs', { year: 'numeric', month: '2-digit', day: '2-digit' })}</ListItemDate>
                    </ListItemShiftContainer>
                    <ListSwapIcon
                      src={swapIcon}
                      beforeInjection={(svg) => {
                        svg.classList.add('svg-class-name')
                        svg.setAttribute('style', 'width: 15px; height: 41px;')
                      }}
                    />
                    <ListItemShiftContainer>
                      <ListItemControllerName>
                        {request?.userRecipientRequest?.firstName + ' ' + request?.userRecipientRequest?.lastName}
                      </ListItemControllerName>
                      <ListItemShiftName>{request?.shiftConfigName2}</ListItemShiftName>
                      <ListItemDate>{new Date(year, month - 1, request?.day2).toLocaleDateString('sr-rs', { year: 'numeric', month: '2-digit', day: '2-digit' })}</ListItemDate>
                    </ListItemShiftContainer>
                  </ListItemShiftsContainer>
                  <ListItemCommentContainer>
                    <p>
                      <b>{t('shiftsChange.explanation')}</b>
                      <br />
                      {request?.comment}
                    </p>
                  </ListItemCommentContainer>
                  <RequestTimestampsBox>
                    {t('shiftsChange.created') + ': '}
                    {new Date(request?.created).toLocaleDateString('sr-rs', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })}
                    <br />
                    {t('shiftsChange.updated') + ': '}
                    {new Date(request?.updated).toLocaleDateString('sr-rs', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })}
                  </RequestTimestampsBox>
                  <RequestResponseBox>
                    <b>
                      {request?.userRecipientRequest?.firstName + ' ' + request?.userRecipientRequest?.lastName + ': '}
                    </b>
                    <br />
                    <UserResponseLabel status={request?.responseOfRecipientRequest}>{t('shiftsChangeResponse.' + request?.responseOfRecipientRequest)}</UserResponseLabel>
                    <br />
                    <b>{t('shiftsChange.scheduleCoordinator') + ': '}</b>
                    <br />
                    <UserResponseLabel status={request?.responseOfSchedulingCoordinator}>{t('shiftsChangeResponse.' + request?.responseOfSchedulingCoordinator)}</UserResponseLabel>
                  </RequestResponseBox>
                  {
                    ((loggedUser?.localPermissions?.[locationId]?.changeWorkShift === 'DISPLAY_AND_APPROVE_WORK_SHIFT_CHANGE' && request?.responseOfSchedulingCoordinator === 'NONE')
                        || (request?.userRecipientRequest?.id === parseInt(loggedUser?.id, 10) && request?.responseOfRecipientRequest === 'NONE'))
                      && (
                      <ListItemActionBox>
                        <Tooltip title={t('shiftsChange.approve')}>
                          <ListItemActionButton type="approve" onClick={() => approveRequestHandler(request)}>
                            <ReactSVG
                              src={approveIcon}
                              beforeInjection={(svg) => {
                                svg.classList.add('svg-class-name')
                                svg.setAttribute('style', 'width: 20px;margin-top: 8px;')
                              }}
                            />
                          </ListItemActionButton>
                        </Tooltip>
                        <Tooltip title={t('shiftsChange.reject')}>
                          <ListItemActionButton type="reject" onClick={() => rejectRequestHandler(request)}>
                            <ReactSVG
                              src={rejectIcon}
                              beforeInjection={(svg) => {
                                svg.classList.add('svg-class-name')
                                svg.setAttribute('style', 'width: 20px;margin-top: 8px;')
                              }}
                            />
                          </ListItemActionButton>
                        </Tooltip>
                      </ListItemActionBox>
                    )
                  }
                </RequestListItem>
              );
            })}
          </RequestListItemsContainer>
        </ShiftContainer>
      </Container>
        )
}

export default ChangeShiftRequests;