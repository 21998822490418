import styled from 'styled-components';
import { Input } from 'antd';

const { TextArea } = Input;

const ATextArea = styled(TextArea)`
  width: 90%;
  height: 150px !important;
  border-radius: 20px;
`;

export default ATextArea;
