import { Button } from 'antd';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';

export const NotificationsSubtitle = styled.div`
    color: ${({ theme: { colors } }) => colors.grayColors.gray};
    font-size: 24px;
    margin-left: 5px;
    line-height: 42px;
`;

export const NotificationsWrapper = styled.div`
    background-color: #e6e6e6 !important;
    flex-wrap: wrap;
    flex-direction: row;
    display: inline-flex;
    justify-content: center;
    gap: 20px;
    align-content: baseline;
    width: 100%;
    margin-top: 10px;
    height: 100%;
`;

export const NotificationPanel = styled.div`
    background-color: ${({ theme: { colors } }) => (colors.mainColors.white)};
    color: ${({ theme: { colors } }) => (colors.mainColors.black)};
    font-size: 14px;
    width: 85%;
    height: 16vh;
    border-radius: 10px;
    box-shadow: 10px 10px 5px rgb(0 0 0/ 0.04);
    display: flex;
    flex-direction: column;
    position: relative;
    height: 140px;
`;

export const NotificationHeader = styled.div`
    border-radius: 10px 10px 0px 0px;
    height: 10%;
`;

export const NotificationContent = styled.div`
    margin: 10px;
    overflow: hidden;
`;

export const NotificationFooter = styled.div`
    position: absolute;
    bottom: 5px;
    right: 10px;
    display: flex;
    // justify-content: space-between; 
    gap: 10px;
`;

export const CreatedTime = styled.div`
    color: ${({ theme: { colors } }) => (colors.grayColors.mediumGray)};
    font-size: 14px;
`;

export const StyledTrashCan = styled(ReactSVG)`
    cursor: pointer;
    display: inherit;
    color: ${({ theme: { colors } }) => (colors.grayColors.mediumGray)};
`;

export const StyledTrashCanTwo = styled(ReactSVG)`
    cursor: pointer;
    display: inherit;
    color: ${({ theme: { colors } }) => (colors.grayColors.mediumGray)};
`;

export const NotificationButton = styled(Button)`
    &.ant-btn {
        background-color: ${({ theme: { colors } }) => (colors.mainColors.white)};
        color: ${({ theme: { colors } }) => (colors.grayColors.gray)};
        box-shadow: 10px 10px 5px rgb(0 0 0/ 0.04);
        display: flex;
        padding: 8px 15px;
        align-items: center;
        outline: none;
        border: none;

        div {
            display: flex;
        }

        span {
            margin-left: 8px;
        }
    }
    &.ant-btn:hover {
    border: none !important;
    background-color: ${({ theme: { colors } }) => (colors.grayColors.lightGray)} !important;
    color: #312f2f !important;
  }
`;
