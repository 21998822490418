import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import AButton from '../../../../core/components/buttons/AButton';

const HTTPErrorPage = ({ code, text, onClickHandler }) => {
  const { t } = useTranslation();

  return (
    <Page>
      <ErrorCode>{code}</ErrorCode>
      <ErrorText>{text}</ErrorText>
      <AButton onClick={onClickHandler}>{t('errorPage.actionButton')}</AButton>
    </Page>
  );
};

const Page = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const ErrorCode = styled.div`
  display: flex;
  font-size: 62px;
  font-weight: bold;
`;

const ErrorText = styled.div`
  display: flex;
  font-size: 22px;
`;

HTTPErrorPage.propTypes = {
    code: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    text: PropTypes.string.isRequired,
    onClickHandler: PropTypes.func.isRequired
};

export default HTTPErrorPage;
