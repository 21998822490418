import styled from 'styled-components';
import bhansaLogo from '../../../assets/images/bhansa-logo-notext.svg';
import antEngineLogo from '../../../assets/images/antEngineLogoInverted.png';

export const Container = styled.div`
 border: none;
 display: flex;
 flex-direction: column;
 align-items: center;
`;

export const BHANSALogo = styled.a`
  position: absolute;
  top: 80px;
  height: 80px;
  width: 80px;
  background-image: url(${bhansaLogo});
  background-repeat: no-repeat;
  // background-size: cover;
`;

export const AntEngineLogo = styled.a`
  position: absolute;
  //right: 3%;
  bottom: 6%;
  background-image: url(${antEngineLogo});
  background-repeat: no-repeat;
  background-size: cover;

  height: 45px;
  width: 190px;
`;

export const Logo = styled.div`
  display: flex;
  position: absolute;
  top: 17%;
  background-image: url(${(props) => props.appImage});
  width: 234px;
  height: 196px;
  background-repeat: no-repeat;
  align-items: center;
  background-size: contain;
`;
