import styled from 'styled-components';
import { Modal, Button } from 'antd';

const FormModalStyled = styled(Modal)`
  .ant-modal-footer {
    display: none;
  }
  .ant-modal-body {
    padding: 0px;
    padding-bottom: 10px;
    border-radius: 5px;
    z-index: 15 !important;
    position: absolute !important;
    top: 50% !important;
  }
  .ant-modal-content {
    border-radius: 5px;
    padding: 0px !important;
    // width: 600px;
  }
  .ant-modal-title {
    color: white;
    font-size: 16px;
    font-weight: 600;
  }
  .ant-modal-close-x {
    color: white;
  }
  .ant-modal-header {
    border-radius: 5px 5px 0px 0px;
    background-color: ${({ theme: { colors } }) => colors.mainColors.blue};
  }
  .ant-btn-default, .ant-btn-primary, .ant-btn {
    color: white !important;
    border-radius: 5px;
    background-color: ${({ theme: { colors } }) => colors.mainColors.blue} !important;
  }
  .ant-btn-default:hover, .ant-btn-primary:hover, .ant-btn:hover {
    color: white !important;
    background-color: ${({ theme: { colors } }) => colors.secondaryColors.darkBlue} !important;
  }
  .ant-btn-primary, .ant-btn {
    color: white !important;
    border-radius: 5px;
    background-color: ${({ theme: { colors } }) => colors.mainColors.blue} !important;
  }
  .ant-btn-primary:hover, .ant-btn:hover {
    color: white !important;
    background-color: ${({ theme: { colors } }) => colors.secondaryColors.darkBlue} !important;
  }
  .ant-btn {
    color: white !important;
    border-radius: 5px;
    background-color: ${({ theme: { colors } }) => colors.mainColors.blue} !important;
  }
  .ant-btn:hover {
    color: white !important;
    background-color: ${({ theme: { colors } }) => colors.secondaryColors.darkBlue} !important;
  }
`;
export const StyledFullName = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: ${({ theme: { colors } }) => colors.grayColors.darkGray};
  margin-bottom: 5%;
`;

export const Container = styled.div`
  padding: 20px;

`;

export const TableContainer = styled.div`
 // padding: 15px;
  overflow: hidden;
  overflow-y: scroll;
  // height: 370px;
 // margin-top: 10px;
  max-height: 370px;
  margin-left: 15px;
`;
export const ContainerPermisions = styled.div`
  padding: 20px;
  overflow: hidden;
  overflow-y: scroll;
  height: 370px;
  @media only screen and (max-width: 1700px) {
    height: 250px;
    }
`;
export const StyledWrapper = styled.div`
display: flex;
flex-direction: row;
width: 100%;
line-height: 2.5;
`;

export const Permission = styled.div`
  font-size: 20px;
  margin-left: 25px;
`;
export const Column = styled.div`
 width: 50%;
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #EFEFEF;
  border-radius: 10px;
  margin-top: 10px;
  `;
export const SpanText = styled.div`
  font-weight: 500;
  max-width: 380px;
`;

export const StyledButton = styled(Button)`
  color: white;
  border-radius: 5px !important;
  margin-top: 5%;
  margin-right: 5%;
`;
export const ButtonContainer = styled.div`
  text-align: right;
`;
export const TitleText = styled.div`
width: 30%;
`;
export const ItemText = styled.div`
width: 50%;
margin-left: 20px;
`;

export const ResponseWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 15px 0 20px 15px;
`;

export const ResponseMessage = styled.div`
  margin-left: 10px;
`;

export default FormModalStyled;
