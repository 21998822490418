/* eslint-disable no-unused-vars */
import React from 'react';;
import { DailyCardWrapper, HeaderWrapper, DayCaption, FooterWrapper } from './MonthlyPlanCard.style';

const MonthlyPlanCard = ({ month, status, day, currentDay, currentMonth, match, matchAbsences, statusAbscence, year }) => {

  const presentMonth = new Date().getMonth() + 1;
  const presentDay = new Date().getDate();
  const currentYear = new Date().getFullYear();
  let thisDay;
  const TitleCardHandler = () => {
    if (day === '' && month === '')
    {
      return
    }
    if (day === undefined || month === undefined) {
      return (currentDay + '.' + currentMonth)
    };
      return (
        day + '.' + month
    )
  };

let backgroundColor;

if(status === undefined) {
    if (statusAbscence === 'GO' || statusAbscence === 'RO' || statusAbscence === 'VB' || statusAbscence === 'PL' || statusAbscence === 'SP' || statusAbscence === 'BO' || statusAbscence === 'A8' || statusAbscence === 'G' || statusAbscence === 'R' || statusAbscence === 'B') {
        backgroundColor = '#FCF1A3';
    }
}else {
    if (status === 'J' || status === 'J6' || status === 'J7' || status === 'J8' || status === 'J9') {
        backgroundColor = '#BFE8E8';
    } else if (status === 'P' || status === 'P14' || status === 'P15' || status === 'P16' || status === 'P17') {
        backgroundColor = '#D5C6D3';
    } else if (status === 'N') {
        backgroundColor = '#B9BDD8';
    } else if (status === 'M10' || status === 'M11' || status === 'M12' || status === 'M13') {
        backgroundColor = '#F6C8BD'
    } else {
        backgroundColor = '#FFFFF';
    }
}



    const FooterColorHandler = () => {
  if (status === undefined && statusAbscence === undefined) {
    return 'aco'
  }
}
const HeaderWrapperColorHandler = () => {
  if (currentDay === presentDay && currentMonth === presentMonth && currentYear === year){
    return 'currentDay';
  }
  if (month !== currentMonth) {
    return 'aco';
  }
}

let finalStatus;
if (status !== undefined) {
  finalStatus = status;
} else {
  finalStatus = statusAbscence;
}

  return (
      <DailyCardWrapper style={{backgroundColor: backgroundColor}}>
      <HeaderWrapper className={HeaderWrapperColorHandler()}>
      {TitleCardHandler()}
      </HeaderWrapper>
      <DayCaption>{finalStatus}</DayCaption>
      <FooterWrapper className={FooterColorHandler()}>
      </FooterWrapper>
      </DailyCardWrapper>
      );
    };

export default MonthlyPlanCard;
