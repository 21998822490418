import styled from 'styled-components';
import { Layout, Menu } from 'antd';

const { SubMenu } = Menu;
const { Sider } = Layout;

export const MenuItemBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const MenuItemBoxSpan = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 16px;
`;

export const MenuItemIconBox = styled.div`
  display: flex;
  position: relative;
  height: 40px;
  width: 40px;
  padding: 10px 10px;
`;

export const SubMenuTitle = styled.div`
  color: ${({ isOpen }) => (isOpen ? 'rgba(0, 0, 0, 0.85)' : undefined)};
  font-weight: ${({ isOpen }) => (isOpen ? 700 : 500)};
`;

export const StyledSubMenu = styled(SubMenu)`
  .ant-menu-submenu-title {
    margin-top: 0;
    margin-bottom: 0;

    span > span {
      text-decoration: ${({ isOpen }) => (isOpen ? 'underline' : undefined)};
    }

    padding-left: 0px !important;
    ul {
      li {
        margin-bottom: 0px !important;
      }
    }
  }

  .ant-menu-submenu-title:hover {
    color: rgba(0, 0, 0, 0.85);

    i {
      color: rgba(0, 0, 0, 0.85) !important;
    }
  }
`;

export const StyledMenu = styled(Menu)`
  background-color: ${({ theme: { colors } }) => (colors.mainColors.blue)};
  position: sticky;
  top: 20px;
  border: 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  li {
    margin-bottom: 0px !important;
    display: flex;
    user-select: none;
    border-radius: 3px;
    ul.ant-menu-sub {
      background: #fff;
    }
  }
`;

export const StyledSider = styled(Sider)`
  .ant-layout-sider-trigger {
    background-color: ${({ theme: { colors } }) => (colors.mainColors.blue)};
    border-radius: 10px;
    color: white;
    position: absolute;
    span {
      position: absolute;
      right: 35px;
      bottom: 20px;
    }
  }
  box-shadow: 5px 0px 10px 0px rgba(0,0,0,0.15);
  background-color: ${({ theme: { colors } }) => (colors.mainColors.blue)};
  border-radius: 5px;
  margin: 10px 0 0 10px;
  height: auto;
  margin-bottom: 25px;
  overflow:hidden;
  padding: 0 10px;
`;

export const StyledMenuItem = styled(Menu.Item)`
  padding: 0px !important;
  &.ant-menu-item {
    border-radius: 0.5rem;
    span {
      color: ${({ selected, theme: { colors } }) => (selected ? colors.mainColors.blue : colors.mainColors.white)};
    }

    svg {
      margin: 0 auto;
      display: block;
      width: auto;
      height: 20px;
      color: ${({ selected, theme: { colors } }) => (selected ? colors.mainColors.blue : colors.mainColors.white)};
    }

    margin-bottom: 0px;
    margin-top: 0;
    margin-left: 0;

    background-color: ${({ selected, theme: { colors } }) => (selected ? colors.mainColors.white : colors.mainColors.blue)};
    &:hover {
      background-color: ${({ selected, theme: { colors } }) => (selected ? colors.mainColors.white : colors.mainColors.lightBlue)};
    }
  }

  &.ant-menu-submenu-title {
    span {
      &:hover {
        color: yellow;
      }
    }
  }

  &.ant-menu-vertical {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const StyledMenuItemValue = styled.span`
  color: ${({ theme: { colors } }) => colors.black};
  font-weight: 500;
  // color: ${({ selected }) => (selected ? '#FFF' : '#000')};
  color: #2b2b2b;
`;

export const StyledSubMenuItemValue = styled.span`
  color: ${({ theme: { colors } }) => colors.mainColors.blue};
  font-weight: 500;
  padding-left: 0px !important;
  color: #2b2b2b;
  background-color: ${({ selected }) => selected && 'red'};
`;

export const Icon = styled.div`
  display: flex;
  background: url(${(props) => props.icon});
  width: 60px;
  height: 60px;
  background-repeat: no-repeat;
`;
