/* eslint-disable arrow-body-style */
import api from './base.service';

export const insertNotification = (notification) => {
    return api().post('/api/notifications', notification);
}

export const seenNotification = (guid) => {
    return api().patch(`/api/notifications/${guid}`);
}

export const getAllNotifications = (userId) => {
    return api().get(`/api/notifications/${userId}`);
};

export const archiveNotification = (guid) => {
    return api().patch(`/api/notifications/archived/${guid}`);
};

export const archiveAllNotifications = (userId) => {
    return api().patch(`/api/notifications/archived/all/${userId}`);
};

export const insertNotifications = (notifications) => {
    return api().post('/api/notifications/bash', notifications);
};
