import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import errorImg from '../../../assets/images/yellow_robot.svg';

const UIError = () => {
    const { t } = useTranslation();

    return (
      <Page>
        <ImageDiv>
          <ErrorImage />
        </ImageDiv>
        <ErrorText>{t('errorPage.UIMessage')}</ErrorText>
      </Page>
    );
};

const Page = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const ErrorText = styled.div`
  display: flex;
  font-size: 22px;
`;

const ImageDiv = styled.div`
  display: flex;
  width: 100%;
  height: 30%;
  justify-content: center;
`;

const ErrorImage = styled.div`
  display: flex;
  height: 350px;
  width: 350px;
  background: url(${errorImg});
  background-repeat: no-repeat;
`;

export default UIError;
