import React from 'react';
import { Container, BHANSALogo, AntEngineLogo } from './LoginPanel.style';
import LoginForm from '../loginForm/LoginForm';

const LoginPanel = () => (
  <Container>
    <BHANSALogo href="https://www.bhansa.gov.ba/" target="_blank" />
    <LoginForm />
    <AntEngineLogo href="https://www.antecna.com/" target="_blank" />
  </Container>
  );

export default LoginPanel;
