import React from 'react'
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import monthlyPlanIcon from '../../assets/images/icons/monthly-calendar.svg';
import changeShiftIcon from '../../assets/images/icons/shuffle-solid.svg'
import notificationsIcon from '../../assets/images/icons/bell-solid.svg';
import { Container, StyledButton, StyledReactSVG, ButtonWrapper, Circle, CircleText } from './Dashboard.style';
import { useNotifications } from '../Notifications/hooks/notifications';
import { useState, useContext, useEffect } from 'react';
import { useAuthStateValue } from '../../context/AuthContext';

const MainHeader = () => {

  const { t } = useTranslation();
  const { loggedUser, setLoggedUser } = useAuthStateValue();

  const { fetchNotifications, loading, dataSource } = useNotifications();
  const notificationsNumber = dataSource?.length;

  useEffect(() => {
    const userId = loggedUser?.id;
    fetchNotifications(userId);
  }, []);

  let emptyNotificationsList = true;
  let showNotificationCircle = false;
  if (notificationsNumber > 0) {
    emptyNotificationsList = false;
    showNotificationCircle = true;
  }

  return (
    <Container>
    <ButtonWrapper>
    <NavLink style={{textDecoration: 'none'}}  to='/monthlyPlan'><StyledButton><StyledReactSVG src={monthlyPlanIcon}></StyledReactSVG><span>{t('main.monthlyPlan')}</span></StyledButton></NavLink>
    <NavLink  style={{textDecoration: 'none'}} to="/changeShift"><StyledButton><StyledReactSVG src={changeShiftIcon}></StyledReactSVG><span>{t('main.changeWorkShifts')}</span></StyledButton></NavLink>
    <NavLink  style={{textDecoration: 'none'}} to="/notifications"><StyledButton><StyledReactSVG src={notificationsIcon}></StyledReactSVG><span>{t('main.notifications')}</span>
    { showNotificationCircle && <Circle>
        <CircleText>{notificationsNumber}</CircleText>
     </Circle> }
    </StyledButton></NavLink>
    </ButtonWrapper>
  </Container>
  )
}

export default MainHeader