import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import sr from './sr.json';

export const supportedLanguages = ['sr'];

const initializeI18N = () => {
    i18n.use(initReactI18next).init({
      resources: {
        sr: {
          translation: sr,
        },
      },
      lng: localStorage.getItem('language'),
      fallbackLng: 'sr',
    });
};

export default initializeI18N;
