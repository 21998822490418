import styled from 'styled-components';

export const DailyCardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 12%;
  height: 80px;
  background-color: white;
  border-radius: 0.5rem;
  box-sizing: border-box;
  overflow: hidden;
  // box-shadow: 5px 0px 10px 0px rgba(0,0,0,0.15);
  position: relative;
  justify-content: space-evenly;

`;

export const HeaderWrapper = styled.div`
  display: block;
  text-overflow: ellipsis;
  height: 25px;
  width: 100%;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 23px;
  border-bottom:  1px solid ${({ theme: { colors } }) => (colors.grayColors.gray)};
  color: ${({ theme: { colors } }) => (colors.grayColors.gray)};
  text-align: center;
  padding-top: 4px;
  background: white;
  &.currentDay {
    background-color: #0073AE;
    color: white !important;
  }
  &.aco {
  z-index: 4;
  background-color: #d7d7d7;
}
  `;

export const DayCaption = styled.div`
  font-size: 20px;
  font-weight: 600;
  `;

export const FooterWrapper = styled.div`
color: ${({ theme: { colors } }) => (colors.grayColors.darkGray)};
display: flex;
height: 55px;
width: 100%;
justify-content: space-between;
position: absolute;
bottom: 0px;
&.aco {
  z-index: 4;
  background-color: #d7d7d7;
}

&.currentDayNotWork {
    background-color: white;
  }
 `;

export const VersionCaption = styled.div`
  font-size: 14px;
  box-sizing: border-box;
  padding: 20px 15px;
    &.currentDay {
    background-color: #0073AE;
    color: white !important;
  }
  `;

export const ActionIconsBox = styled.div`
  display: flex;
  padding: 12px;
  gap: 20px 10px;
`;


export const NotInRangeOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
`;

export const Status = styled.div`

  border-bottom: 1px solid red;
  font-size: 12px;
  text-align: center;
`;