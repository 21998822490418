/* eslint-disable no-unused-vars */
import { ConfigProvider } from 'antd';
import srSR from 'antd/lib/locale/sr_RS';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './App.css';
import styled from 'styled-components';
import { useApplicationStateValue } from './context/ApplicationContext';
import { useAuthStateValue } from './context/AuthContext';
import BasicLayout from './layout/BasicLayout/BasicLayout';
import Login from './pages/login/Login';
import BasicHeader from './layout/BasicHeader/BasicHeader';
import Dashboard from './pages/dashboard/Dashboard';
import { Route, Switch } from 'react-router-dom';
import MonthyPlan from './pages/monthlyPlan/MonthyPlan';
import Notifications from './pages/Notifications/Notifications';
import ChangeShift from './pages/changeShift/ChangeShift';
import PageNotFound from './pages/PageNotFound';
import RequestForChangeShift from './pages/changeShift/requestForChangeShift/RequestForChangeShift';
import ChangeShiftRequests from './pages/changeShift/changeShiftRequests/ChangeShiftRequests';

const Main = () => {
  const { isLoading } = useApplicationStateValue();
  const { loggedUser, isLoggedIn, logout } = useAuthStateValue();
  const { t } = useTranslation();
  const { language } = useApplicationStateValue();

  let lang = '';
  if (language === 'sr') {
    lang = srSR;
  } else {
    lang = null;
  }
  return (
    <ConfigProvider locale={lang}>
        {isLoggedIn ? (<Wrapper>
          <BasicHeader/>
          
          <Switch>
          <Route path='/' exact component={Dashboard}></Route>
          <Route path='/monthlyPlan' component={MonthyPlan}></Route>
          <Route path='/changeShift' exact component={ChangeShift}></Route>
            <Route path={'/changeShift/requestForChangeShift'} component={RequestForChangeShift}></Route>
            <Route path={'/changeShift/changeShiftRequests'} component={ChangeShiftRequests}></Route>
          <Route path='/notifications' component={Notifications}></Route>
          <Route path='*' component={PageNotFound}></Route>
          </Switch>
          </Wrapper>) : (<Login></Login>)}
      
    </ConfigProvider>
  );
};
export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #e6e6e6;
`
export default Main;
