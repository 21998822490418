import engineApi from './engine.service';

// http://localhost:7025/engine/schedule/monthly/ATCU1/2023/2/all

export const getMonthlyAll = (atcu, year, month) => {
    return engineApi().get(`/engine/schedules/monthly/${atcu}/${year}/${month}/all`);
};

// http://localhost:7025/engine/schedule/monthly/ATCU1/2023/5/1/content

export const getMonthlyContent = (atcu, year, month, version) => {
    return engineApi().get(`/engine/schedules/monthly/${atcu}/${year}/${month}/${version}/content`);
}

export const getScheduleContent = (atcu, year, month, version) => {
    return engineApi().get(`/engine/schedules/monthly/${atcu}/${year}/${month}/${version}/content`);
  };

export const getScheduleDemand = (atcu, year, month, version) => {
    return engineApi().get(`/engine/schedules/monthly/${atcu}/${year}/${month}/${version}/demand`);
};

export const getScheduleVersions = (atcu, year, month) => {
    return engineApi().get(`/engine/schedules/monthly/${atcu}/${year}/${month}/all`);
};
