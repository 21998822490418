import React from 'react'
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container, StyledButton, StyledReactSVG, ButtonWrapper, Circle, CircleText } from '../dashboard/Dashboard.style';
import { useAuthStateValue } from '../../context/AuthContext';
import useShiftsChange from './hooks/useChangeShifts';


const ChangeSfihtHeader = () => {

  const { t } = useTranslation();
  const { loggedUser } = useAuthStateValue();

  const locationIdentificator = loggedUser?.extendUser?.locationIdentificator;
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const { requestList } = useShiftsChange({ atcu: locationIdentificator, year, month, userId: loggedUser?.id });
  const notificationsNumber = requestList?.length;

  let emptyNotificationsList = true;
  let showNotificationCircle = false;
  if (notificationsNumber > 0) {
    emptyNotificationsList = false;
    showNotificationCircle = true;
  }

  return (
    <Container>
    <ButtonWrapper>
    <NavLink style={{textDecoration: 'none'}}  to='/changeShift/changeShiftRequests'><StyledButton><StyledReactSVG></StyledReactSVG><span>{t('changeShift.changeShiftRequests')}</span>
    <Circle>
              <CircleText>{notificationsNumber}</CircleText>
            </Circle>
    </StyledButton></NavLink>
    <NavLink  style={{textDecoration: 'none'}} to='/changeShift/requestForChangeShift'><StyledButton><StyledReactSVG></StyledReactSVG><span>{t('changeShift.requestForChangeShift')}</span></StyledButton></NavLink>
    </ButtonWrapper>
  </Container>
  )
}

export default ChangeSfihtHeader