import styled from 'styled-components';
import { Button, Select } from 'antd';
import AButton from '../../../core/components/buttons/AButton';
import { StyledButton } from '../../dashboard/Dashboard.style';

export const SelectComponentsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
`;

export const FilterSelectInput = styled(Select)`
    &.ant-select {
    border-radius: 20px;
    width: ${(props) => props?.filterlenght};
    overflow: hidden;
    border: 1px solid gainsboro;
    width: 300px;
    box-shadow: 5px 0px 10px 0px rgba(0,0,0,0.15);
    .ant-select-selector {
      border: none;
      outline: none;
      height: 50px;
      .ant-select-selection-search {
        .ant-select-selection-search-input {
          outline: none;
          border: none;
        }
      }
      .ant-select-selection-item {
        display: flex;
        font-size: 18px;
        width: 100%;
        align-self: center;
        justify-content: center;
      }
      /* .ant-select-selector:disabled {
        background-color: red !important;
      }
      &:disabled {
        background-color: red !important;
      } */
    }
    /* .ant-select-disabled {
        background-color: red;
    }
    &:disabled {
        background-color: red !important;
      } */
  }
  box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.15);
`;

export const ChangeShiftButton = styled(StyledButton)`

  
`