/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-undef */
import React from 'react'
import MainHeader from './MainHeader';
import {useTranslation} from 'react-i18next';
import {useAuthStateValue} from '../../context/AuthContext';
import {Container, StyledDivLogout, StyledReactSVG, LogoutButton, UserWrapper, Name, Email, MainFooter, OuterContainer} from './Dashboard.style';
import logoutIcon from '../../assets/images/icons/sign-out-alt-solid.svg';

const Dashboard = () => {
    const {loggedUser, logout} = useAuthStateValue();

    const {t} = useTranslation();

    const handleLogout = () => {
        logout();
    };

    const name = loggedUser?.firstName + ' ' + loggedUser?.lastName;
    const email = loggedUser?.username;

    return (
        <OuterContainer>
            <MainHeader></MainHeader>
            <MainFooter>
                <UserWrapper>
                    <Name>{name}</Name>
                    <Email>{email}</Email>
                </UserWrapper>
                <LogoutButton onClick={handleLogout}><StyledDivLogout><StyledReactSVG
                    src={logoutIcon}></StyledReactSVG>{t('buttons.logout')}</StyledDivLogout></LogoutButton>
            </MainFooter>
        </OuterContainer>
    )
}

export default Dashboard
