import React from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ApplicationContext } from '../../context/ApplicationContext';
import { CustomErrorType } from './enum';
import HTTPErrorPage from './custom/http/HTTPErrorPage';
import UIError from './UI/UI';

const ErrorType = {
    UI: 1,
    CUSTOM: 2
};

class GlobalErrorBoundary extends React.Component {
    static contextType = ApplicationContext;

    constructor(props) {
        super(props);
        this.state = { hasError: false, errorType: null };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, error, errorType: ErrorType.UI };
    }

    componentDidUpdate() {
        if (this.context.error && !this.state.hasError) {
            this.setState({
                hasError: true,
                errorType: ErrorType.CUSTOM
            });
        }
    }

    componentDidCatch() {
        // TODO Should error be logged somewhere?
        // logErrorToMyService(error, errorInfo);
    }

    returnFromError = () => {
        this.setState({ hasError: false, errorType: null });
        this.context.showError(null);
    };

    render() {
        if (this.state.hasError) {
            if (ErrorType.UI === this.state.errorType) {
                return <UIError />;
            }
            switch (this.context.error?.type) {
                case CustomErrorType.HTTP:
                    return <HTTPErrorPage onClickHandler={this.returnFromError} code={404} text="Not found" />;
                case CustomErrorType.OTHER:
                    return <div>koja god</div>;
                default:
                    return <div>default</div>;
            }
        }

        return this.props.children;
    }
}

GlobalErrorBoundary.contextType = ApplicationContext;

GlobalErrorBoundary.propTypes = {
    children: PropTypes.any
};

export default withTranslation()(withRouter(GlobalErrorBoundary));
