import { Button } from "antd";
import styled from "styled-components";
import { ReactSVG } from "react-svg";

export const StyledButtonBack = styled(Button)`
    display: flex;
    position: absolute;
    left: 4%;
    background-color: transparent;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: none !important;
    height: 40px;
    width: 50px;
    
    &:hover{
        background-color: transparent !important;
    }
`;

export const Container = styled.div`
    padding: 20px 0px;
    gap: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #e6e6e6;
    padding-top: 120px;
 
`;

export const StyledReactSVG = styled(ReactSVG)`
    fill:  ${({ theme: { colors } }) => colors.grayColors.gray};
    margin-top: 7px;
    margin-right: 50px;
`;

export const Title = styled.div`
    display: flex;
    flex-direction: row;

`;

export const Text = styled.div`
    font-size: 24px;
    color: #312f2f;
    align-self: center;
    line-height: 42px;
    @media only screen and (max-width: 320px) {
        font-size: 22px;
    }
    /* @media only screen and (max-width: 290px) {
        font-size: 20px;
    } */
`;