import styled from 'styled-components';

export const Container = styled.div`
  // display: flex;
  background-image: url(${(props) => props.backgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  flex-direction: row;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;  
`;

export const Panel = styled.div`
  margin-top: 140px;
  display: flex;
  width: 100%;
  // height: 200px;
  flex-direction: column;
  justify-content: center;
`;

export const AppInfoContainer = styled.div`
  // height: 200px;
  padding: 50px 0px;
  text-align: center;
  color: white;
  & > h2 {
    color: white;
  }
`;
