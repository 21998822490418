import { Button } from 'antd';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';

// export const Container = styled.div`
//     padding: 60px 0px;
//     gap: 30px;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     overflow: hidden;
//     overflow-y: auto;
//     @media screen and (max-height: 670px) {
//         padding: 10px 0px;
//         height: 65vh;
//     }
//     @media screen and (max-height: 570px) {
//         padding: 5px 0px;
//         height: 57vh;
//     }
//
// `;


export const OuterContainer = styled.div`
    height: 100vh;
    position: fixed;
    width: 100%;
`;

export const Container = styled.div`
    margin-top: 100px;
    height: calc(100% - 215px);
    display: flex;
`;

export const StyledButton = styled(Button)`
    background-color: ${({ theme: { colors } }) => colors.mainColors.blue};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 10px;
    font-size: 20px;
    color: white;
    border-radius: 10px !important;
    border: none;
    padding: 0;
    height: 80px;
    width: 300px;
    text-decoration: none !important;
    position: relative;
    @media only screen and (max-width: 320px) {
        width: 280px;
    }
    @media only screen and (max-width: 300px) {
        width: 240px;
    }
    span {
        color: white;
        text-align: center;
        text-decoration: none !important;

    }
    a {
        text-decoration: none !important;
    }
    
    &:hover{
        border: 1px #0073ae solid;
        color: #0073ae !important;
        background-color: #185da9 !important;
    }
    &:disabled {
    background-color: #858585 !important;
    color: #fff !important;
    &:hover {
      background-color: #858585 !important;
      border: none !important;
    }
  }
`;
export const StyledDivLogout = styled.div`
    display: flex;
    background-color: #fab10b !important;
    flex-direction: row;
    align-items:center;
    border-radius: 10px !important;
    justify-content: center;
    padding: 0;
    height: 40px;
    color: white;
    text-decoration: none !important;
`;
export const StyledReactSVG = styled(ReactSVG)`
    color: white;
    margin-top: 10px;
    margin-right: 5px;
    svg {
        height: 23px;
    }
`;
export const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 35px;
    width: 100%;
    @media screen and (max-height: 570px) {
      gap: 5px;
   }
`;

export const LogoutButton = styled(Button)`
    position: absolute;
    height: 40px;
    left: 25%;
    font-size: 18px;
    color: white;
    border-radius: 10px !important;
    border: none;
    padding: 0;
    width: 50%;
    margin-top: 10px;
    span {
        color: white;
        text-align: center;
    }

`;

export const UserWrapper = styled.div`
    width: 100%;
    text-align: center;
    margin-top: 10px;
`;
export const Name = styled.div`
    text-align: center;
    font-weight: 600;
    line-height: 12px;
    color:  ${({ theme: { colors } }) => colors.grayColors.gray};
    `;
export const Email = styled.div`
    text-align: center;
    color:  ${({ theme: { colors } }) => colors.grayColors.gray};
`;

export const Circle = styled.div`
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #fab10b;
    position: absolute;
    top: -17px;
    right: -17px;
    text-align: center;
    font-size: 21px;
    color: white;
`;

export const CircleText = styled.div`
    text-align: center;
    font-size: 21px;
    padding-top: 1px;
`;

export const MainFooter = styled.div`
    overflow: hidden;
    position: fixed;
    bottom: 0;
    height: 115px;
    width: 100%;
`;