import styled from "styled-components";
import { Button } from 'antd';
import { ReactSVG } from 'react-svg';

export const StyledButton = styled(Button)`
    background-color: ${({ theme: { colors } }) => colors.mainColors.blue};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 10px;
    font-size: 19px;
    color: white;
    border-radius: 10px !important;
    border: none;
    padding: 0;
    height: 80px;
    width: 300px;
    text-decoration: none !important;
    span {
        color: white;
        text-align: center;
        text-decoration: none !important;

    }
    a {
        text-decoration: none !important;
    }
    
    &:hover{
        border: 1px #0073ae solid;
        color: #0073ae !important;
        background-color: #185da9 !important;
    }
`;

export const ButtonWrapper = styled.div`
/* padding: 60px 0px; */
display: flex;
flex-direction: column;
gap: 35px;
@media screen and (max-height: 570px) {
       gap: 0px;
}
`;

export const Circle = styled.div`
    width: 35px !important;
    height: 35px;
    border-radius: 50%;
    background-color: #fab10b;
    position: relative;
    top: -100px;
    right: -287px;
    text-align: center;
    font-size: 21px;
`;

export const CircleText = styled.div`
    text-align: center;
    font-size: 21px;
    padding-top: 1px;
    color: white;
`;
