import FormModalStyled from './FormModalStyled';

const { confirm } = FormModalStyled;

function showConfirm (props) {
  confirm({ ...props });
}

const confirmModal = (props) => showConfirm(props);

export default confirmModal;
