import engineApi from './engine.service';

export const sendShiftChangeRequest = (payload) => {
  return engineApi().post('/engine/replacement-shifts/request', payload);
};

export const getShiftChangeRequests = (userId) => {
  return engineApi().get(`/engine/replacement-shifts/${userId}`);
};

export const sendShiftRequestResponse = (payload) => {
  return engineApi().patch('/engine/replacement-shifts/response', payload);
};