/* eslint-disable react/jsx-key */
import React, {useEffect, useState} from 'react';
import {Route, Switch, NavLink} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Dashboard from '../dashboard/Dashboard';
import {StyledButtonBack, Container, StyledReactSVG, Title, Text} from '../Pages.style';
import backIcon from '../../assets/images/icons/chevron-left-solid.svg'
import forwardIcon from '../../assets/images/icons/chevron-right-solid.svg';
import MonthlyPlanCard from './monthlyPlanCard/MonthlyPlanCard';
import { Spin } from 'antd';
import {
    DailyCardWrapper,
    DailyPlanWrapper,
    WeekDayWrapper,
    WeekDay,
    StyledLeftButton,
    StyledRightButton,
    TextFooterVersion,
    StyledSVGLeft,
    FooterContainer,
    TextFooter,
    StyledSVGRight,
    NoMonthlyPlansWrapper,
    NoMonthlyPlansMessage
} from './MonthyPlan.style';
import {useAuthStateValue} from '../../context/AuthContext';
import {useMonthlyPlan} from './hooks/monthlyPlan';
import {ReactSVG} from 'react-svg';
import faceFrown from '../../assets/images/icons/face-meh-solid.svg';

const MonthyPlan = () => {

    const {t} = useTranslation();
    const [year, setYear] = useState(new Date().getFullYear());
    const [selectedtMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
    const [currentMonthName, setCurrentMonthName] = useState(new Date().getMonth());
    const presentMonth = new Date().getMonth() + 1;
    const {loggedUser} = useAuthStateValue();
    const [version, setVersion] = useState("");

    const [datesToDisplay, setDatesToDisplay] = useState([]);

    const atcu = loggedUser?.extendUser?.locationIdentificator

    const updateDatesToDisplay = (dataAll, dataContent) => {

        const firstDayOfCurrentMonth = new Date(year, selectedtMonth - 1, 1);

        const firstDayIndex = firstDayOfCurrentMonth.getDay();

        const daysFromPreviousMonth = firstDayIndex > 0 ? firstDayIndex - 1 : 6;

        const firstDayToDisplay = new Date(year, selectedtMonth - 1, 1 - daysFromPreviousMonth);

        const datesToDisplayLocal = [];

        const result = dataContent?.controllers?.find((item) => (item?.firstName === loggedUser?.firstName) && (item?.lastName === loggedUser?.lastName));
        const month = dataContent?.month;

        if (selectedtMonth === month) {
            for (let i = 0; i < 42; i++) {
                const currentDate = new Date(firstDayToDisplay.getFullYear(), firstDayToDisplay.getMonth(), firstDayToDisplay.getDate() + i);
                const currentDay = currentDate.getDate();
                const currentMonth = currentDate.getMonth() + 1;
                const currentYear = currentDate.getFullYear();
                const match = result?.shifts?.find((item) => item.date === currentDay && month === currentMonth);
                const matchAbsences = result?.absences?.find((item) => item.date === currentDay && month === currentMonth);
                datesToDisplayLocal.push({
                    currentDay: currentDay,
                    currentMonth: currentMonth,
                    currentYear: currentYear,
                    match: match,
                    matchAbsences: matchAbsences
                });
            }
        }

        setVersion(dataAll?.[0]?.versions?.[0]?.version);
        setDatesToDisplay(datesToDisplayLocal);
    }

    const {fetchMonthlyAll, dataSourceMonthlyAll, dataSourceMonthlyContent, loading} = useMonthlyPlan({
        atcu,
        year,
        selectedtMonth,
        updateDatesToDisplay
    });

    const weekDaysData = [
        {
            key: 'Ponedjeljak',
            label: 'Ponedjeljak',
            value: 'P'
        },
        {
            key: 'Utorak',
            label: 'Utorak',
            value: 'U'
        },
        {
            key: 'Srijeda',
            label: 'Srijeda',
            value: 'S'
        },
        {
            key: 'Četvrtak',
            label: 'Četvrtak',
            value: 'Č'
        },
        {
            key: 'Petak',
            label: 'Petak',
            value: 'P'
        },
        {
            key: 'Subota',
            label: 'Subota',
            value: 'S'
        },
        {
            key: 'Nedjelja',
            label: 'Nedjelja',
            value: 'N'
        },
    ];

    const monthNames = ["Januar", "Februar", "Mart", "April", "Maj", "Jun",
        "Jul", "Avgust", "Septembar", "Oktobar", "Novembar", "Decembar"
    ];

    useEffect(() => {
        fetchMonthlyAll(atcu, year, selectedtMonth);
    }, [selectedtMonth]);


    const increaseMonth = () => {
        setSelectedMonth(selectedtMonth === 12 ? 1 : selectedtMonth + 1);
        setCurrentMonthName(currentMonthName === 11 ? 0 : currentMonthName + 1);
        setYear(selectedtMonth === 12 ? year + 1 : year);

    }
    const decreaseMonth = () => {
        setSelectedMonth(selectedtMonth === 1 ? 12 : selectedtMonth - 1);
        setCurrentMonthName(currentMonthName === 0 ? 11 : currentMonthName - 1);
        setYear(selectedtMonth === 1 ? year - 1 : year);
    }

    const noMonthlyPlans = t('monthlyPlan.noMonthlyPlansMessage');

    const currentYear = new Date().getFullYear();

    return (
        <Container>
            <div className={"loading"+(loading?" shown":" not-shown")}><Spin /></div>
            <Title>
                <StyledButtonBack><NavLink to='/'><StyledReactSVG
                    src={backIcon}></StyledReactSVG></NavLink></StyledButtonBack>
                <Text>
                    {t('main.monthlyPlan')}</Text>
            </Title>

            <DailyPlanWrapper>
                <WeekDayWrapper>
                    {loading?"":weekDaysData?.map((item) => {
                        return (
                            <WeekDay>{item.value}</WeekDay>
                        )
                    })}
                </WeekDayWrapper>
                <DailyCardWrapper>
                    {loading ? "" : (datesToDisplay?.length === 0) ? (
                        <NoMonthlyPlansWrapper>
                                <ReactSVG src={faceFrown} style={{color: 'gray'}}/>
                                <NoMonthlyPlansMessage>{noMonthlyPlans}</NoMonthlyPlansMessage>
                        </NoMonthlyPlansWrapper>
                    ) : datesToDisplay?.map((item) => {
                        return (
                            <MonthlyPlanCard
                                key={item.key}
                                 month={selectedtMonth}
                                days={item?.match?.days}
                                status={item?.match?.shiftName}
                                statusAbscence={item?.matchAbsences?.absenceName}
                                day={item?.match?.date}
                                currentDay={item?.currentDay}
                                currentMonth={item?.currentMonth}
                                match={item?.match}
                                matchAbsences={item?.matchAbsences}
                                year={year}
                            />
                        )
                    })}
                </DailyCardWrapper>
            </DailyPlanWrapper>
            <FooterContainer>
                <StyledLeftButton onClick={decreaseMonth}><StyledSVGLeft
                    src={backIcon}></StyledSVGLeft></StyledLeftButton>
                <TextFooter
                    className={((presentMonth === selectedtMonth) && (year === currentYear)) ? 'currentMonth' : ''}>{monthNames[currentMonthName] + ' ' + year + '.'}
                    <TextFooterVersion>{t('monthlyPlan.version')}{version}</TextFooterVersion></TextFooter>
                <StyledRightButton onClick={increaseMonth}><StyledSVGRight
                    src={forwardIcon}></StyledSVGRight></StyledRightButton>
            </FooterContainer>
            <Switch>
                <Route path='/' exact component={Dashboard}></Route>

            </Switch>
        </Container>
    )
}

export default MonthyPlan
