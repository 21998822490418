import styled from 'styled-components';
import { SwapOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ReactSVG } from 'react-svg';

export const ShiftContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #e6e6e6;
    width: 100%;
`;
 //#dbdbdb

export const ArrowChangeShiftContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const MyContainer = styled.div`
    display: flex;
    flex-direction: column;
   // border: 1px solid lightGray;
    border: 1px solid ${({ theme: { colors } }) => colors.grayColors.grayDark};
    border-radius: 5px;
   // width: 175px;
   // width: 45vw;
    min-height: 150px;
    justify-content: center;
    background-color: white;
    margin-top: 10px;
    box-shadow: 2px 0px 8px 1px #00000024;
    width: 44vw !important;
`;

export const SwapIcon = styled(SwapOutlined)`
    font-size: 30px;
    color: ${({ theme: { colors } }) => colors.grayColors.darkerGray};
    transform: scale(0.8);
`;

export const NameSurnameField = styled.div`
    font-size: 17px;
    text-align: center;
    color: ${({ theme: { colors } }) => colors.grayColors.darkerGray};
`;

export const DateField = styled.div`
    font-size: 17px;
    text-align: center;
    font-weight: bold;
    color: ${({ theme: { colors } }) => colors.grayColors.darkerGray};
`;

export const ShiftField = styled.div`
    font-size: 35px;
    margin-top: 10px;
    text-align: center;
    font-weight: bold;
    color: ${({ theme: { colors } }) => colors.grayColors.darkerGray};
`;

export const ChangeShiftFooter = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 1vh;
`;

export const StyledButtonAccept = styled(Button)`
    width: 100px;
    height: 35px; 
    border-radius: 10px !important; 
    font-size: 16px; 
    width: 44vw !important; 
    background-color: #369d29;
    color: white;
    margin: 2.5vw;
    &:hover{
        border: 1px #369d29 solid;
        color: white !important;
        background-color: #369d29 !important;
    }
    &:disabled {
    background-color: #858585 !important;
    &:hover {
      background-color: #858585 !important;
      border: none !important;
    }
    }
`;

export const StyledButtonQuit = styled(Button)`
    width: 100px;
    height: 35px; 
    border-radius: 10px !important; 
    font-size: 16px; 
    width: 44vw !important; 
    background-color: #9d2323;
    color: white;
    margin: 2.5vw;
        &:hover{
        border: 1px #9d2323 solid;
        color: white !important;
        background-color: #9d2323 !important;
    }
    &:disabled {
    background-color: #858585 !important;
    &:hover {
      background-color: #858585 !important;
      border: none !important;
    }
    }
`;

export const StatusRibbon = styled.div`
    width: 100%;
    height: 40px;
    background-color: #369d29;
    color: white;
    margin-top: 10px;
    line-height: 40px;
    padding-left: 20px;
`;

export const RequestListContainer = styled.div`
{
  display: flex;
  height: 100%;
  margin-top: 25%;
  flex-direction: column;
  color: white;
  font-size: 16px;
  background-color: #e6e6e6;
  user-select: none;
}
`;

export const RequestListTitleContainer = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 75px;
  text-wrap: wrap;
  flex-direction: row;
  text-align: center;
  width: 100%;
  background-color: #0073AE;
  //line-height: 50px;
  justify-content: space-between;
  & > div > div > svg {
    width: 15px;
    cursor: pointer;
  }
`;

export const RequestListTitle = styled.div`
  width: 180px;
  min-width: 180px;
  padding: 10px;
`;

export const CloseRequestListIcon = styled(ReactSVG)`
  width: 45px;
  min-width: 45px;
  height: 30px;
`;

export const RequestListItemsContainer = styled.div`
  width: 100%;
  height: 100%;
  align-items: center;
  /* overflow-y: auto; */
  color:  #5F5F5F;
  text-align: center;
  padding-bottom: 10px;
`;

export const RequestListItem = styled.div`
  width: 100%;
  transition: all 0.5s;
  max-height: ${(props) => (props?.expanded ? '900px' : '140px')};
  background-color: #efefef;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px;
  gap: 8px;
  align-items: center;
  overflow: hidden;
  margin-top: 10px;
`;

export const ListItemShiftContainer = styled.div`
  width: 45%;
  height: 90px;
  border-radius: 5%;
  border: 1px solid #D9D9D9;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

export const ListItemShiftName = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

export const ListItemDate = styled.div`
  font-size: 12px;
  font-weight: 600;
`;

export const ListItemControllerName = styled.div`
  height: 30px;
  font-size: 12px;
  display: flex;
  align-items: center;
  flex-direction: row;
  text-wrap: balance;
`;

export const ListSwapIcon = styled(ReactSVG)`
  width: 10%;
  z-index: 9;
`;

export const RequestStatusLabel = styled.div`
  position: relative;
  color: white;
  font-weight: 600;
  width: 100%;
  text-align: center;
  font-size: 12px;
  height: 30px;
  line-height: 30px;
  background-color: #0073AE;
  border-radius: 5px 5px 0 0;
  text-align: center;
`;

export const ListItemShiftsContainer = styled.div`
 display: flex;
 flex-direction: row;
 align-items: center;
 width: 100%;
`;

export const ListItemCommentContainer = styled.div`
  border-radius: 5px;
  padding: 5px;
  border: 1px solid #D9D9D9;
  background-color: white;
  display: flex;
  width: 100%;
  text-align: left;
  font-size: 12px;
`;

export const ListItemActionBox = styled.div`
  width: 100%;
  // border-radius: 5px;
  // padding: 5px;
  // border: 1px solid #D9D9D9;
  // background-color: white;
  display: flex;
  justify-content: space-between;
`;

export const ListItemActionButton = styled.div`
  width: 49%;
  cursor: pointer;
  background-color: #cccccc;
  border: 1px solid #cccccc;
  height: 30px;
  border-radius: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  transition: all 0.5s;
  &:hover {
    background-color: #efefef;
    border: 1px solid #cccccc;
    color: ${(props) => (props?.type === 'approve' ? '#0F8F3E' : '#C44536')};
  }
`;

export const RequestResponseBox = styled.div`
  width: 100%;
  // background-color: white;
  padding: 0 0 5px 5px;
  border-radius: 5%;
  // border: 1px solid #cccccc;
  font-size: 12px;
  text-align: left;
`;

export const UserResponseLabel = styled.span`
  color: ${(props) => (props?.status === 'NONE' ? '#DC9900' : (props?.status === 'APPROVED' ? '#0f8f3e' : '#C44536'))};
  font-weight: 600;
`;

export const RequestListItemCaret = styled(ReactSVG)`
  height: 30px;
  cursor: pointer;
  position: absolute;
  transition: all 0.5s;
  transform-origin: center; 
  transform: ${(props) => (props?.expanded ? 'rotate(90deg) translate(-5px, -5px)' : '')};
`;

export const RequestTimestampsBox = styled.div`
  width: 100%;
  font-size: 12px;
  text-align: left;
  padding: 0 0 0 5px;
`;

export const NoRequestsLabel = styled.div`
  text-align: center;
  font-size: 22px;
  margin-top: 30px;
  /* width: 200px; */
`;