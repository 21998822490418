import React from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import errorImage from '../assets/images/yellow_robot.svg';

const PageNotFound = () => {

  const { t } = useTranslation();

    return (
      <Container>
        <StyledSVG src={errorImage}></StyledSVG>
        <Text>{t('errorPage.UIMessage')}</Text>
      </Container>
    );
};

export const Container = styled.div`
background-color: white;
height: 100%;
`;
export const Text = styled.div`
  font-size: 26px;
  text-align: center;
`;
export const StyledSVG = styled(ReactSVG)`
`;

export default PageNotFound;
