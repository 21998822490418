/* eslint-disable react/jsx-filename-extension */
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { QuestionOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { getAllNotifications, archiveNotification, archiveAllNotifications } from '../../../services/notifications.service';
import confirmModal from '../../../core/components/modals/ConfirmModal';
import { useAuthStateValue } from '../../../context/AuthContext';
import StatusCode from '../../../services/statusCode';

export const useNotifications = () => {
    const [loading, setLoading] = useState(false);
    const [dataSource, setDataSource] = useState();
    const { loggedUser } = useAuthStateValue();
    const { t } = useTranslation();
    const userId = loggedUser?.id;

    const fetchNotifications = async (userId) => {
        try {
            setLoading(true);
            const response = await getAllNotifications(userId);
            setDataSource(response.data);
        } catch (e) {
            setDataSource(null);
        } finally {
            setLoading(false);
        }
    }

    const deleteNotification = async (record) => {
        const response = await archiveNotification(record.guid);
        if (response.status === StatusCode.OK) {
            fetchNotifications(userId);
            message.success(t('users.deletionSuccess'));
        } else {
            message.error(t('users.deletionError'));
    }
    }

    const onNotificationRemove = (record) => {
        confirmModal({
          centered: false,
          icon: <QuestionOutlined />,
          title: t('notifications.delete'),
          content: t('notifications.deleteQuestion'),
          okText: t('buttons.yes'),
          cancelText: t('buttons.no'),
          onOk: async () => {
             await deleteNotification(record);
             const userId = loggedUser?.id;
             fetchNotifications(userId);
          }
        })
      }

      const onAllNotificationsRemove = () => {
        const userId = loggedUser?.id;
        confirmModal({
          centered: true,
          icon: <QuestionOutlined />,
          title: t('notifications.deleteAllNotifications'),
          content: t('notifications.deleteAllQuestion'),
          okText: t('buttons.yes'),
          cancelText: t('buttons.no'),
          onOk: async () => {
            await archiveAllNotifications(userId);
            fetchNotifications(userId);
          }
        });
      }

    return {
        fetchNotifications,
        deleteNotification,
        
        onNotificationRemove,
        onAllNotificationsRemove,
        loading,
        dataSource
    }
}
