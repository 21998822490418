import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useAuthStateValue } from '../../../context/AuthContext';
import StatusCode from '../../../services/statusCode';
import { getMonthlyAll, getMonthlyContent } from '../../../services/monthlyPlan.service';


export const useMonthlyPlan = ({atcu, year, selectedtMonth, updateDatesToDisplay}) => {
    const [loading, setLoading] = useState(true);
    const [monthlyContentLoaded, setMonthlyContentLoaded] = useState(false);
    const [dataCalculated, setDataCalculated] = useState(false);
    const [dataSourceMonthlyAll, setDataSourceMonthlyAll] = useState();
    const [dataSourceMonthlyContent, setDataSourceMonthlyContent] = useState();


    const updateLoading = () => {
        if(loading && monthlyAllLoaded && monthlyContentLoaded) {
            debugger;
            updateDatesToDisplay();
            setLoading(false);
        }
        else setLoading(true);
    }

    const fetchMonthlyAll = async () => {
        setLoading(true);
        let dataAll = [];
        let dataContent = [];

        try {
            const response1 = await getMonthlyAll(atcu, year, selectedtMonth);

            const version = response1.data?.[0]?.versions?.[0]?.version;
            dataAll = response1.data;

            const response2 = await getMonthlyContent(atcu, year, selectedtMonth, version);

            setDataSourceMonthlyAll(response1.data);
            setDataSourceMonthlyContent(response2.data);

            dataContent = response2.data;

        } catch {
            setDataSourceMonthlyAll([]);
            setDataSourceMonthlyContent([]);
        } finally {
            updateDatesToDisplay(dataAll, dataContent);
            setLoading(false);
        }
    }

    return {
        fetchMonthlyAll,
        dataSourceMonthlyAll,
        dataSourceMonthlyContent,
        dataCalculated,
        setDataCalculated,
        loading,
        updateLoading
    }
}