import React from 'react';
import { Route, Switch, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Dashboard from '../dashboard/Dashboard';
import { StyledButtonBack, Container, StyledReactSVG, Title, Text } from '../Pages.style';
import backIcon from '../../assets/images/icons/chevron-left-solid.svg';
import { useNotifications } from './hooks/notifications';
import { useAuthStateValue } from '../../context/AuthContext';
import moment from 'moment';
import { useState, useContext, useEffect } from 'react';
import { NotificationsSubtitle, NotificationsWrapper, NotificationPanel, NotificationHeader, NotificationContent, NotificationFooter, StyledTrashCan, StyledTrashCanTwo, NotificationButton, CreatedTime } from './Notifications.style';
import trashCan from '../../assets/images/icons/icon_trash-can.svg'
import { ThemeContext } from 'styled-components';
import { ReactSVG } from "react-svg";
import styled from "styled-components";
import faceFrown from '../../assets/images/icons/face-meh-solid.svg';

const Notifications = () => {

  // const [isModalOpen, setIsModalOpen] = useState(false);

  // const showModal = () => {
  //   setIsModalOpen(true);
  // };

  // const handleOk = () => {
  //   setIsModalOpen(false);
  // };

  // const handleCancel = () => {
  //   setIsModalOpen(false);
  // };

  const { t } = useTranslation();

  const { loggedUser, setLoggedUser } = useAuthStateValue();
  const themeContext = useContext(ThemeContext);

  const { fetchNotifications, deleteNotification, onNotificationRemove, onAllNotificationsRemove, loading, dataSource } = useNotifications();

  const notificationsSubtitle = '(' + dataSource?.length + ')';
  const trashButton = t('notifications.deleteAll');

  useEffect(() => {
    const userId = loggedUser?.id;
    fetchNotifications(userId);
  }, []);

  
  const noNotifications = t('notifications.noData');

  return (
    <Container>
    <Title style={{ marginBottom: '-15px' }}>
      <StyledButtonBack><NavLink to='/'><StyledReactSVG src={backIcon}></StyledReactSVG></NavLink></StyledButtonBack>
      <Text>
        {t('main.notifications')}</Text>
      <NotificationsSubtitle>{notificationsSubtitle}</NotificationsSubtitle>
    </Title>
    <NotificationsWrapper>
      {(dataSource?.length === 0) ? (
           <NoMonthlyPlansWrapper>
           <ReactSVG src={faceFrown} style={{ color: 'gray' }} />
           <NoMonthlyPlansMessage>{noNotifications}</NoMonthlyPlansMessage>
         </NoMonthlyPlansWrapper>
      ) : (
        dataSource?.map((item, index) => {
          const colorFunction = {
            "HIGH": themeContext.colors.mainColors.red,
            "MEDIUM": themeContext.colors.mainColors.orange,
            "LOW": themeContext.colors.mainColors.green
          }

          const dateAndTime = (moment(item.created).format('DD.MM.YYYY HH:mm:ss'));
          return (
              <NotificationPanel key={index}>
              <NotificationHeader style={{ backgroundColor: colorFunction[item.priority] }} />
              <NotificationContent dangerouslySetInnerHTML={{ __html: item.content }} />
              <NotificationFooter>
                <CreatedTime dangerouslySetInnerHTML={{ __html: dateAndTime }} />
                <StyledTrashCan
                  src={trashCan}
                  onClick={() => {
                    onNotificationRemove(dataSource[index])
                  }}
                />
              </NotificationFooter>
            </NotificationPanel>
        )}
        )
        )}
      </NotificationsWrapper>
  <Switch>
    <Route path='/' exact component={Dashboard}></Route>

  </Switch>
  </Container>
  )
}

export const NoMonthlyPlansWrapper = styled.div`
  height: 75vh; 
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const NoMonthlyPlansMessage = styled.div`
  font-size: x-large;
  text-align: center;
`;

export default Notifications
