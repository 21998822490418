import React from 'react';
import PropTypes from 'prop-types';
import {
  Username,
  LogoBox,
  RightSideBox,
  SideMenuLogo,
  UserInfo,
  StyledLayoutHeader,
  UserRole,
  Title
} from './BasicHeader.style';

import { useApplicationStateValue } from '../../context/ApplicationContext';
import { supportedLanguages } from '../../i18n/init';
import { languages } from '../../i18n/langs';
import bhansaLogo from '../../assets/images/bhansa-logo-notext.svg';
import config from '../../util/config';

const BasicHeader = ({ children, user, menuItems }) => {
  const { changeLanguage } = useApplicationStateValue();

  const changeLanguageHandler = (languageCode) => {
    changeLanguage(languageCode);
  };

  const openLogoLink = () => {
    window.open(config.LOGO_LINK, '_blank', 'noopener,noreferrer');
  };

  const appLanguages = supportedLanguages.map((language) => ({
    key: language,
    value: languages[language],
    callback: () => {
      changeLanguageHandler(language);
    }
  }));

  return (
    <StyledLayoutHeader height={config.HEADER_HEIGHT} className="container">
      <LogoBox onClick={openLogoLink}>
        <SideMenuLogo src={bhansaLogo} alt="logo" />
      </LogoBox>
      <RightSideBox>
        {/* {children}
        <UserInfo>
          <Username>
            {user?.firstName}
            {' '}
            {user?.lastName}
          </Username>
          <UserRole>{user?.username}</UserRole>
        </UserInfo>
        <HeaderDropdown
          user={user}
          menuItems={menuItems}
          languages={appLanguages}
          changeLanguageHandler={changeLanguageHandler}
        /> */}
      <Title>BHANSA Raspored</Title>
      </RightSideBox>
    </StyledLayoutHeader>
  );
};

BasicHeader.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    username: PropTypes.string,
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ])
  }).isRequired
};

export default BasicHeader;
