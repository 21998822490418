import styled from 'styled-components';
import { Button } from 'antd';

const AButton = styled(Button)`
  &.ant-btn {
    color: white;
    border-radius: 10px;
    background: ${({ theme: { colors } }) => colors.mainColors.red};
    min-width: 96px;
    min-height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    font-size: 16px;
    outline: none;
    border: none;
  }
`;

export default AButton;
