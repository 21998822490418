import React, { createContext, useContext, useState } from 'react';

const Context = createContext(null);

export const PageContextProvider = ({ children }) => {
  const [key, setKey] = useState('');

  const contextValue = {
    key,
    setKey
  };
  return <Context.Provider value={{ ...contextValue }}>{children}</Context.Provider>;
};

export const usePageContext = () => {
  return useContext(Context);
};
