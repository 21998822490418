/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

let dom = {};
try {
  //eslint-disable-next-line no-undef
  dom = require('react-router-dom');
} catch (e) {
  console.error(e);
}
const { Route, Redirect } = dom;
let whatToExport;
if (dom.Route) {
  whatToExport = ({ component: Component, allowed, redirectUrl, children, store, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        allowed ? (
          children ? (
            React.cloneElement(children, {
              ...props,
              store
            })
          ) : (
            <Component store={store} {...props} />
          )
        ) : (
          <Redirect
            to={{
              pathname: redirectUrl,
              state: {
                from: props.location
              }
            }}
          />
        )
      }
    />
  );
  whatToExport.propTypes = {
    component: PropTypes.func,
    allowed: PropTypes.object
  };
} else {
  whatToExport = () => <div>No React Router</div>;
}

export default whatToExport;
