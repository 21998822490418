import { Button } from "antd";
import styled from "styled-components";
import { ReactSVG } from "react-svg";

export const DailyPlanWrapper = styled.div`
display: flex;
flex-direction: column;
position: relative;
gap: 10px;
overflow: hidden;
overflow-y: auto;
margin-bottom: 55px;
`;

export const WeekDayWrapper = styled.div`
display:flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: space-between;
margin-left: 5px;
margin-right: 5px;

`;

export const WeekDay = styled.div`
font-size: 16px;
width: 10%;
text-align: center;
color: ${({ theme: { colors } }) => (colors.grayColors.darkGray)};

`;

export const DailyCardWrapper = styled.div`
display: flex;
flex-wrap: wrap;
justify-content: space-between;
gap: 8px;
padding-right: 5px;
padding-left: 5px;
overflow: hidden;
overflow-y: auto;
`;

export const StyledLeftButton = styled(Button)`
    display: flex;
    background-color: transparent;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: none !important;
    width: 30px;
    height: 50px;
    margin-left: 10px;
    margin-top: 5px;

    &:hover, &:focus {
        border: none !important;
        background-color: transparent !important;
    }
`;

export const StyledRightButton = styled(Button)`
    display: flex;
    background-color: transparent;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: none !important;
    width: 30px;
    height: 50px;
    margin-right: 10px;
    margin-top: 5px;

    &:hover, &:focus {
        border: none !important;
        background-color: transparent !important;
    }
`;

export const StyledSVGRight = styled(ReactSVG)`
    fill:  ${({ theme: { colors } }) => colors.grayColors.lightGray};
    position: absolute;
    right: 0;
`;

export const StyledSVGLeft = styled(ReactSVG)`
    fill:  ${({ theme: { colors } }) => colors.grayColors.lightGray};
    position: absolute;
    left: 0;
`;

export const Title = styled.div`
    display: flex;
    flex-direction: row;

`;

export const TextFooter = styled.div`
    font-size: 21px;
    align-self: center;
    color: ${({ theme: { colors } }) => colors.grayColors.lightGray};

    &.currentMonth {
        font-weight: 700;
    }

`;
export const TextFooterVersion = styled.div`
    font-size: 13px;
    font-weight: 400;
    margin-top:-8px;
    opacity: 0.6;
    text-transform: uppercase;
    text-align: center;


`

export const FooterContainer = styled.div`
    display: flex;
    flex-direction: row;
    position: fixed;
    justify-content: space-between;
    bottom: 0;
    width: 100%;
    height: 55px;
    z-index: 500;
    background-color: ${({ theme: { colors } }) => colors.mainColors.blue};
`;

export const NoMonthlyPlansMessage = styled.div`
  font-size: x-large;
  text-align: center;
`;

export const NoMonthlyPlansWrapper = styled.div`
  height: 75vh; 
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;