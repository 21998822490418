/* eslint-disable no-unused-vars */
import { useTranslation } from 'react-i18next';
import { Container, Panel, AppInfoContainer } from './Login.style';
import backgroundImage from '../../assets/images/air_traffic_control_tower_sunset_small.png';
import LoginPanel from './loginPanel/LoginPanel';

const Login = () => {
  const { t } = useTranslation();

  return (
    <Container backgroundImage={backgroundImage}>
      <Panel>
        <AppInfoContainer>
            <h2><span style={{fontWeight:300}}>BHANSA</span> <span>RASPORED</span></h2>
        </AppInfoContainer>
        <LoginPanel app="App" />
      </Panel>
    </Container>
  );
};

export default Login;
