import { useForm, Controller } from 'react-hook-form';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import passwordIcon from '../../../assets/images/icons/password-key.svg';
import { Form, ValidateField, LoginInputField, LoginInputPasswordField, ErrorSpace, ErrorText, LoginButton } from './LoginForm.style';
import userIcon from '../../../assets/images/icons/user-alt.svg';
import { useAuthStateValue } from '../../../context/AuthContext';

const LoginForm = () => {
  const { t } = useTranslation();

    const validationSchema = object().shape({
      username: string().required(t('login.emailIsRequired')),
      password: string().required(t('login.passwordIsRequired'))
    });

  const {
    formState: { errors },
    control,
    handleSubmit
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onBlur'
  });

  const { login } = useAuthStateValue();

  const onSubmit = async ({ username, password }) => {
    try {
      await login({
        username,
        password
      });
    } catch (e) {
       message.error(t('login.failedLogin'));
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        key="username"
        name="username"
        defaultValue=""
        control={control}
        render={({ field }) => (
          <ValidateField>
            <LoginInputField
              prefix={<ReactSVG src={userIcon} />}
              onChange={field.onChange}
              value={field.value}
              placeholder={t('login.email').toUpperCase()}
            />
            {/* eslint-disable-next-line max-len */}
            <ErrorSpace>{errors.username && <ErrorText>{errors.username.message}</ErrorText>}</ErrorSpace>
          </ValidateField>
        )}
      />
      <Controller
        key="password"
        name="password"
        defaultValue=""
        control={control}
        render={({ field }) => (
          <ValidateField>
            <LoginInputPasswordField
              prefix={<ReactSVG src={passwordIcon} />}
              onChange={field.onChange}
              value={field.value}
              placeholder={t('login.password').toUpperCase()}
            />
            {/* eslint-disable-next-line max-len */}
            <ErrorSpace>{errors.password && <ErrorText>{errors.password.message}</ErrorText>}</ErrorSpace>
          </ValidateField>
        )}
      />
      <LoginButton htmltype="submit">{t('login.signIn')}</LoginButton>
    </Form>
  );
};

export default LoginForm;
